import * as BABYLON from 'babylonjs';

export default class MeshComponent {
    public name: string;
    public material:BABYLON.Material;

    /**
     *
     */
    constructor(name:string, material:BABYLON.Material) {
        this.name = name;
        this.material = material;
    }
}
