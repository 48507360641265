import ConfiguratorScene from "./configuratorScene"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import { ShedType } from "./Enums/shedType";
import SceneManager from "./sceneManager";
import * as configuratorConfig from "../configurator.json"
import ko from "knockout/build/output/knockout-latest.js"
import "knockout.validation/dist/knockout.validation.js"
import "knockout.validation/localization/nl-NL.js"
import { SelectorGroup } from "babylonjs-gui/2D/controls/selector";
import { SceneLoaderFlags } from "babylonjs/Loading/sceneLoaderFlags";
import { PBRBaseMaterial } from "babylonjs/Materials/PBR/pbrBaseMaterial";
import { WallType } from "./Enums/wallType";
import { RoofType } from "./Enums/roofType";
import { SmallWallType } from "./Enums/smallWallType";
import { PositioningDirection } from "./Enums/positioningDirection";
import { GutterOption } from "./Enums/gutterOption";
import { HtmlElementTexture } from "babylonjs";

window.addEventListener("DOMContentLoaded", async () => {
  // Create the game using the "renderCanvas".
  let configuratorScene = new ConfiguratorScene("renderCanvas");

  // Create the scene.
  await configuratorScene.createScene();

  document.getElementById("nav-front-tab").addEventListener("click", () => { configuratorScene.rotateCameraToDirection(PositioningDirection.Front) });
  document.getElementById("nav-back-tab").addEventListener("click", () => { configuratorScene.rotateCameraToDirection(PositioningDirection.Back) });
  document.getElementById("nav-left-tab").addEventListener("click", () => { configuratorScene.rotateCameraToDirection(PositioningDirection.Left) });
  document.getElementById("nav-right-tab").addEventListener("click", () => { configuratorScene.rotateCameraToDirection(PositioningDirection.Right) });

  document.getElementById("pills-facade-tab").addEventListener("click", () => { (document.querySelector('#nav-tab-facade > .nav-link[aria-selected=true]') as HTMLElement).click() });

  document.querySelectorAll(".select-wall-color").forEach(el => el.addEventListener("click", event => {
    configuratorScene.setWallColor((event.target as HTMLSelectElement).value);
  }));

  var viewModel = function (shedType: string, length: number, width: string, height: number, smallWallHeight: number, wallType: string, roofType: string, smallWallType: string, gutter: string) {
    var self = this;

    this.shedType = ko.observable(shedType);
    this.width = ko.observable(width);
    this.height = ko.observable(height);
    this.smallWallHeight = ko.observable(smallWallHeight);
    this.availableSmallWallHeights = ko.observableArray([
      { value: "0.0", text: "Geen" },
      { value: "0.25", text: "25 centimeter" },
      { value: "0.5", text: "50 centimeter" },
      { value: "0.70", text: "70 centimeter" },
      { value: "1.0", text: "1 meter" }
    ]);
    this.availableRoofTypesArray = [
      {
        label: "Ongeïsoleerd",
        children: [
          { value: "CorrugatedIron", text: "Vezelcement golfplaat"},
          { value: "LarssenSheetPiling", text: "Damwand profielplaat"},
          { value: "LarssenSheetPilingAntiCondense", text: "Damwand profielplaat (met anti-condens vilt)"},
        ]
      },
      {
        label: "Geïsoleerd",
        children: [
          { value: "SandwichPanelTrapezium_40", text: "Sandwichpaneel trapezium, 40/71 mm. (Rc 1,90)"},
          { value: "SandwichPanelTrapezium_60", text: "Sandwichpaneel trapezium, 60/91 mm. (Rc 2,90)"},
          { value: "SandwichPanelTrapezium_80", text: "Sandwichpaneel trapezium, 80/111 mm. (Rc 3,95)"},
          { value: "SandwichPanelTrapezium_100", text: "Sandwichpaneel trapezium, 100/131 mm. (Rc 4,95)"},
          { value: "SandwichPanelTrapezium_115", text: "Sandwichpaneel trapezium, 115/146 mm. (Rc 6,40)"},
          { value: "SandwichPanelCorrugatedIron_40", text: "Sandwichpaneel golf, 40/83 mm. (Rc 2,73)"},
          { value: "SandwichPanelCorrugatedIron_60", text: "Sandwichpaneel golf, 60/103 mm. (Rc 3,50)"},
          { value: "SandwichPanelCorrugatedIron_80", text: "Sandwichpaneel golf, 80/123 mm. (Rc 4,35)"},
        ]
      }
    ];
    this.availableRoofTypes = ko.observableArray(this.availableRoofTypesArray);
    this.availableWallTypesArray = [
      {
        label: "Ongeïsoleerd",
        children: [
          { value: "LarssenSheetPiling", text: "Damwand profielplaat"},
          { value: "Clapboard", text: "Potdeksel profielplaat"},
        ]
      },
      {
        label: "Geïsoleerd",
        children: [
          { value: "SandwichPanel_45", text: "Sandwichpaneel, 45 mm. (Rc 1,80)"},
          { value: "SandwichPanel_60", text: "Sandwichpaneel, 60 mm. (Rc 2,75)"},
          { value: "SandwichPanel_80", text: "Sandwichpaneel, 80 mm. (Rc 3,80)"},
          { value: "SandwichPanel_88", text: "Sandwichpaneel, 88 mm. (Rc 4,80)"},
          { value: "ClapboardAndSandwichPanel_40", text: "Sandwichpaneel en potdeksel profielplaat, 40 mm. (Rc 1,77)"},
          { value: "ClapboardAndSandwichPanel_60", text: "Sandwichpaneel en potdeksel profielplaat, 60 mm. (Rc 2,72)"},
          { value: "ClapboardAndSandwichPanel_80", text: "Sandwichpaneel en potdeksel profielplaat, 80 mm. (Rc 3,65)"},
        ]
      }
    ];
    this.availableWallTypes = ko.observableArray(this.availableWallTypesArray);
    this.availableSmallWallTypesArray = [
      {
        label: "Ongeïsoleerd",
        children: [
          { value: "None", text: "Geen Trasraam profielplaat"},
          { value: "DampProof", text: "Trasraam profielplaat (tot peil)"},
          { value: "Concrete_10", text: "Betonpaneel, 10 cm. type PABE 10"},
          { value: "Concrete_14", text: "Betonpaneel, 14 cm. type PABE 14"},
        ]
      },
      {
        label: "Geïsoleerd",
        children: [
          { value: "ConcreteIsolated_16A", text: "Betonpaneel, 16 cm. type SAFEBE 16-4 PS (Rc 1,12)"},
          { value: "ConcreteIsolated_16B", text: "Betonpaneel, 16 cm. type SAFEBE 16-4 PIR (Rc 1,89)"},
          { value: "ConcreteIsolated_20A", text: "Betonpaneel, 20 cm. type SAFEBE 20-6 PIR (Rc 2,81)"},
          { value: "ConcreteIsolated_20B", text: "Betonpaneel, 20 cm. type SAFEBE 20-8 PIR (Rc 3,70)"},
          { value: "ConcreteIsolated_25", text: "Betonpaneel, 25 cm. type SAFEBE 25-10 PIR (Rc 4,63)"}
        ]
      },
    ];
    this.availableSmallWallTypes = ko.observableArray(this.availableSmallWallTypesArray);
    this.availableLengths = ko.observableArray([
      { value: "10", text: "10 meter" },
      { value: "15", text: "15 meter" },
      { value: "20", text: "20 meter" },
      { value: "25", text: "25 meter" },
      { value: "30", text: "30 meter" },
      { value: "40", text: "40 meter" },
      { value: "60", text: "60 meter" },
      { value: "70", text: "70 meter" },
      { value: "80", text: "80 meter" }]);

    this.availableHeights = ko.observableArray();
    this.availableWidths = ko.observableArray();

    this.length = ko.observable(length);
    this.lengthChanged = async function (refresh: boolean = true) {
      var configForShedType = configuratorConfig.ValidSizesPerShedType[self.shedType()];
      self.availableWidths.removeAll();
      self.availableHeights.removeAll();
      for (var element of configForShedType) {
        if (element.lengths.indexOf(Number.parseInt(self.length())) != -1) {
          if (self.availableWidths().some(item => element.width.value == item.value) == false) {
            self.availableWidths.push({ "value": element.width.value, "text": element.width.text })
          }
          if(element.width.value == self.width()){
            if (self.availableHeights().some(item => element.height.value == item.value) == false) {
              self.availableHeights.push({ "value": element.height.value, "text": element.height.text })
            }
          }
        }
      }
      await configuratorScene.setShedLength(Number.parseFloat(self.length()), refresh);
    }
    this.lengthChanged(false);

    this.widthChanged = async function (refresh: boolean = true) {
      var configForShedType = configuratorConfig.ValidSizesPerShedType[self.shedType()];
      self.availableLengths.removeAll();
      self.availableHeights.removeAll();
      for (var element of configForShedType) {
        if (element.width.value == self.width()) {
          if (self.availableHeights().some(item => element.height.value == item.value) == false) {
            self.availableHeights.push({ "value": element.height.value, "text": element.height.text })
          }
          for (var length of element.lengths) {
            if (self.availableLengths().some(item => length == item.value) == false) {
              self.availableLengths.push({ "value": length, "text": length + " meter" })
            }
          }
        }
      }
      await configuratorScene.setShedWidth(self.width(), refresh);
    }
    this.widthChanged(false);

    this.availableWallColors = ko.observableArray();
    this.availableRoofColors = ko.observableArray();
    this.availableSmallWallColors = ko.observableArray();
    this.availableFacadeElementColors = ko.observableArray();
    this.availableGutters = ko.observableArray();
    this.availableSolarPanels = ko.observableArray();
    this.availableFoundations = ko.observableArray();

    this.wallType = ko.observable(wallType);
    this.roofType = ko.observable(roofType);
    this.smallWallType = ko.observable(smallWallType);
    this.inputGutter = ko.observable(gutter);

    this.wallColor = ko.observable();
    this.roofColor = ko.observable();
    this.smallWallColor = ko.observable();
    this.smallWallMaterial = ko.observable();

    this.facadeElementToAdd = ko.observable();
    this.facadeElementColor = ko.observable();
    this.outLineDirection = ko.observable();
    this.facadeElements = ko.observableArray();

    this.inputEnvironmentalPermit = ko.observable();
    this.inputDelivery = ko.observable();
    this.inputFoundation = ko.observable();
    this.inputSolarPanels = ko.observable();

    this.inputFirstName = ko.observable().extend({ required: true });
    this.inputLastName = ko.observable().extend({ required: true });
    this.inputGender = ko.observable().extend({ required: true });


    this.inputBusinessName = ko.observable();
    this.inputEmailAddress = ko.observable().extend({ required: true });
    this.inputPhoneNumber = ko.observable().extend({ required: true });
    this.inputZipcode = ko.observable().extend({ required: true });
    this.inputHouseNumber = ko.observable().extend({ required: true });
    this.inputBuildLocationZipcode = ko.observable().extend({ required: true });
    this.inputBuildLocationHouseNumber = ko.observable().extend({ required: true });

    this.images = ko.observableArray();

    this.wallTypeChangedEvent = async function () {
      await this.wallTypeChanged(true, true);
    }

    //change wallType color list from Configurator
    this.wallTypeChanged = async function (refresh: boolean = true, updateColor: boolean = true) {
      await self.updateTypeList();

      let wallTypeColor: string = self.wallType().split("_")[0];
      var colors = configuratorConfig.Colors["Wall." + wallTypeColor];
      self.availableWallColors.removeAll();
      for (var color of colors) {
        self.availableWallColors.push({ "value": color.Color, "name": color.Name, "priceFactor": color.PriceFactor })
      }
      await configuratorScene.setWallType(self.wallType(), refresh);
      if (colors.length > 0 && updateColor) {
        let currentColor = self.wallColor();
        if (!colors.map(c => c.Color).includes(currentColor)) {
          self.wallColor(colors[0].Color);
        }
      }
      await self.wallColorChanged();
      if (refresh) {
        self.saveToLocalStorage();
      }
    }

    this.wallColorChanged = async function () {
      configuratorScene.setWallColor(self.wallColor());
      self.saveToLocalStorage();
    }

    this.roofTypeChangedEvent = async function () {
      await this.roofTypeChanged(true, true);
    }

    //change rooftype color list from Configurator
    this.roofTypeChanged = async function (refresh: boolean = true, updateColor: boolean = true) {
      await self.updateTypeList();
      if(self.isRoofNotIsolated()) {
        if(self.wallType() == "SandwichPanel_45" || 
           self.wallType() == "SandwichPanel_60" || 
           self.wallType() == "SandwichPanel_80" || 
           self.wallType() == "SandwichPanel_88" || 
           self.wallType() == "ClapboardAndSandwichPanel_40" || 
           self.wallType() == "ClapboardAndSandwichPanel_60" || 
           self.wallType() == "ClapboardAndSandwichPanel_80") {
            self.wallType("LarssenSheetPiling");
            self.wallTypeChanged();
        }
      }

      let roofTypeColor: string = self.roofType().split("_")[0];

      var colors = configuratorConfig.Colors["Roof." + roofTypeColor];
      self.availableRoofColors.removeAll();
      for (var color of colors) {
        self.availableRoofColors.push({ "value": color.Color, "name": color.Name, "priceFactor": color.PriceFactor })
      }
      await configuratorScene.setRoofType(self.roofType(), refresh);
      if (colors.length > 0 && updateColor) {
        let currentColor = self.roofColor();
        if (!colors.map(c => c.Color).includes(currentColor)) {
          self.roofColor(colors[0].Color);
        }
      }
      await self.roofColorChanged();
      if (refresh) {
        self.saveToLocalStorage();
      }
    }

    this.roofColorChanged = function () {
      configuratorScene.setRoofColor(self.roofColor());
      self.saveToLocalStorage();
    }

    this.smallWallTypeChangedEvent = async function () {
      await this.smallWallTypeChanged(true, true);
    }

    //change smallwallType color list from configurator
    this.smallWallTypeChanged = async function (refresh: boolean = true, updateColor: boolean = true) {
      if(self.smallWallType() == "DampProof" && self.smallWallHeight() == '0.0'){
        self.smallWallHeight("0.1");
        await configuratorScene.setSmallWallHeight(0.1);
      }
      else{
        await configuratorScene.setSmallWallHeight(self.smallWallHeight());
      }
      let smallWallTypeColor: string = self.smallWallType().split("_")[0];

      var colors = configuratorConfig.Colors["SmallWall." + smallWallTypeColor];
      self.availableSmallWallColors.removeAll();
      for (var color of colors) {
        self.availableSmallWallColors.push({ "value": color.Color, "name": color.Name, "priceFactor": color.PriceFactor, "material": color.Material });
      }
      await configuratorScene.setSmallWallType(self.smallWallType(), false);
      if (colors.length > 0 && updateColor) {
        let currentColor = self.smallWallColor();
        if (!colors.map(c => c.Color).includes(currentColor)) {
          self.smallWallColor(colors[0].Color);
        }
      }
      await self.smallWallColorChanged(refresh);
      if (refresh) {
        self.saveToLocalStorage();
      }
    }

    this.smallWallColorChanged = async function (refresh: boolean = true) {
      let smallWallMaterial = self.smallWallMaterial();
      self.availableSmallWallColors().forEach(colorObject => {
        if (colorObject.value === self.smallWallColor()) {
          smallWallMaterial = colorObject.material;
        }
      });
      await configuratorScene.setSmallWallColor(self.smallWallColor(), smallWallMaterial, refresh);
      if (refresh) {
        self.saveToLocalStorage();
      }
    }

    this.facadeElementChangedEvent = async function () {
      setTimeout(async () => {
        await this.facadeElementChanged(true, true);
      }, 200);
    }

    this.facadeElementChanged = async function (refresh: boolean = true, updateColor: boolean = true) {
      let facadeElementColor: string = self.facadeElementToAdd().split("_")[0];

      var colors = configuratorConfig.Colors[facadeElementColor];
      self.availableFacadeElementColors.removeAll();
      for (var color of colors) {
        self.availableFacadeElementColors.push({ "value": color.Color, "name": color.Name, "priceFactor": color.PriceFactor, "material": color.Material });
      }
      if (colors.length > 0 && updateColor) {
        let currentColor = self.facadeElementColor();
        if (!colors.map(c => c.Color).includes(currentColor)) {
          self.facadeElementColor(colors[0].Color);
        }
      }
      if (refresh) {
        self.saveToLocalStorage();
      }
    }

    this.gutterChangedEvent = async function () {
      await this.gutterChanged(true);
    }

    this.gutterChanged = async function (updateScene: boolean = true) {
      await configuratorScene.setGutter(self.inputGutter(), updateScene);
      if (updateScene) {
        self.saveToLocalStorage();
      }
    }

    this.selectShedType = async function (shedType: string) {
      self.shedType(shedType);
      self.length(35.0);
      self.width("Size8");
      self.height(3.0);
      self.smallWallHeight(0);
      self.lengthChanged(false);
      self.widthChanged(false);
      self.inputDelivery("1");
      await configuratorScene.initShed(self.shedType(), self.length(), self.width(), self.height(), self.smallWallHeight(), self.wallType());
      self.shedTypeChanged();
      
      document.getElementById("pills-size-tab").click();
    };

    this.shedTypeChanged = async function () {
      self.availableGutters.removeAll();
      for (var gutter of configuratorConfig.ValidGuttersPerShedType[self.shedType()]) {
        self.availableGutters.push({ "value": gutter.value, "text": gutter.text });
      }
      self.availableSolarPanels.removeAll();
      for (var solarPanel of configuratorConfig.ValidSolarPanelsPerShedType[self.shedType()]) {
        self.availableSolarPanels.push({ "value": solarPanel.value, "text": solarPanel.text });
      }
      if(this.shedType === ShedType.SideWaysShed && self.inputGutter() === "TwoSides")
      {
        self.inputSolarPanels(self.availableSolarPanels()[0].value);
        self.inputGutter(self.availableGutters()[0].value);
      }
      await configuratorScene.setGutter(self.inputGutter(), false);
      self.updateTypeList();

      this.saveToLocalStorage();
    }

    this.heightChanged = async function () {
      await configuratorScene.setShedHeight(Number.parseFloat(self.height()));
      this.saveToLocalStorage();
    }

    this.smallWallHeightChanged = async function () {
      await configuratorScene.setSmallWallHeight(self.smallWallHeight());
      await self.updateSmallWallType();
      await self.updateTypeList();
      this.saveToLocalStorage();
    }

    this.updateSmallWallType = async function(){
      if(self.shouldNotShowDampProof()) {
        if(self.smallWallType() == "DampProof" || self.smallWallType() == "None"){
          self.smallWallType("Concrete_10");
          await configuratorScene.setSmallWallType(self.smallWallType(), false);
        }
      }
      else{
        if(self.smallWallType() != "DampProof" && self.smallWallType() != "None"){
          self.smallWallType("None");
          await configuratorScene.setSmallWallType(self.smallWallType(), false);
        }
      }
      self.smallWallTypeChanged(true,true);
    }

    this.addFacadeElement = async function (positioningDirection: string) {
      var id = await configuratorScene.addFacadeElement(self.facadeElementToAdd(), positioningDirection, self.outLineDirection(), self.facadeElementColor());
      if (id !== -1) {
        var color = self.availableFacadeElementColors().filter(where => where.value == self.facadeElementColor())[0];
        self.facadeElements.push({ "id": id, "element": self.facadeElementToAdd(), "positioningDirection": positioningDirection, "outLineDirection": self.outLineDirection(), "colorCode": color.value, "colorName": color.name, "priceFactor": color.priceFactor })
      }
    }

    this.removeFacadeElement = async function (id: number) {
      await configuratorScene.removeFacadeElement(id);
      self.facadeElements.remove(function (element) {
        if (element.id === id) {
          return true;
        }
        return false;
      });
      this.saveToLocalStorage();
    }

    this.getElementText = function (element: String) {
      return document.querySelector(`#selectFacadeElement > optgroup > option[value=${element}]`).textContent;
    }
    this.getDirectionText = function (direction: String) {
      return document.querySelector(`#selectOutlineDirection > option[value=${direction}]`).textContent;
    }

    this.errors = ko.validation.group(this);

    this.clearShedFromLocalStorage = function () {
      localStorage.removeItem("configuratorData");
      location.reload();
    }

    this.saveToLocalStorage = function () {
      var jsonData = ko.toJSON(this);
      localStorage.setItem("configuratorData", jsonData);
    }

    this.canSubmit = ko.computed(function () {
      return self.errors().length == 0;
    });

    this.shouldNotShowDampProof = ko.computed(function(){
      return self.wallType() != "LarssenSheetPiling" || self.smallWallHeight() != "0.0";
    });
    
    this.isRoofNotIsolated = ko.computed(function(){
      return self.roofType() == "CorrugatedIron" || self.roofType() == "LarssenSheetPiling" || self.roofType() == "LarssenSheetPilingAntiCondense";
    });

    this.updateTypeList = function(){
      if(self.isRoofNotIsolated()){
        if(self.availableWallTypes().length > 1){
          self.availableWallTypes(self.availableWallTypesArray.slice(0,1));
        }
      }
      else{
        self.availableWallTypes(self.availableWallTypesArray);
      }
      if(!(self.smallWallHeight() == '0.0' && self.shouldNotShowDampProof)){
        if(self.wallType() == "LarssenSheetPiling" && (self.smallWallHeight() == "0.0" || self.smallWallHeight() == "0.1")){
          let chosenSmallWallTypes = JSON.parse(JSON.stringify(self.availableSmallWallTypesArray));
          chosenSmallWallTypes = chosenSmallWallTypes.slice(0,1);
          chosenSmallWallTypes[0].children = chosenSmallWallTypes[0].children.slice(0,2);
          self.availableSmallWallTypes(chosenSmallWallTypes);
        }
        else {
          let chosenSmallWallTypes = JSON.parse(JSON.stringify(self.availableSmallWallTypesArray));
          chosenSmallWallTypes[0].children = chosenSmallWallTypes[0].children.slice(2,chosenSmallWallTypes[0].children.length);
          self.availableSmallWallTypes(chosenSmallWallTypes);
        }
      }
      else{
        let chosenSmallWallTypes = JSON.parse(JSON.stringify(self.availableSmallWallTypesArray));
        chosenSmallWallTypes = chosenSmallWallTypes.slice(0,1);
        chosenSmallWallTypes[0].children = chosenSmallWallTypes[0].children.slice(0,2);
        self.availableSmallWallTypes(chosenSmallWallTypes);
        self.smallWallType("None");
      }
    };

    this.loadFromLocalStorage = async function () {
      var jsonData = localStorage.getItem("configuratorData");
      if (jsonData != null) {
        var data = JSON.parse(jsonData);

        self.shedType(data["shedType"]);
        self.width(data["width"]);
        self.height(Number(data["height"]));
        self.smallWallHeight(Number(data["smallWallHeight"]));
        self.length(Number(data["length"]));

        self.lengthChanged(false);
        self.widthChanged(false);

        self.wallType(data["wallType"]);
        self.roofType(data["roofType"]);
        self.smallWallType(data["smallWallType"]);
        self.smallWallMaterial(data["smallWallMaterial"]);
        self.wallColor(data["wallColor"]);
        self.roofColor(data["roofColor"]);
        self.smallWallColor(data["smallWallColor"]);
        self.outLineDirection(data["outLineDirection"]);

        this.shedTypeChanged();
        this.wallTypeChanged(false, false);
        this.smallWallTypeChanged(false, false);
        this.roofTypeChanged(false, false);
        this.facadeElementChanged(false, true);
        this.wallColorChanged();
        this.roofColorChanged();
        this.smallWallColorChanged();
        this.inputDeliveryChanged();

        self.facadeElements(data["facadeElements"]);
        await configuratorScene.initShed(self.shedType(), self.length(), self.width(), self.height(), self.smallWallHeight(), self.wallType(), false);
        await configuratorScene.setRoofType(self.roofType(), false);
        await configuratorScene.setSmallWallType(self.smallWallType(), false);
        configuratorScene.setWallColor(self.wallColor());
        configuratorScene.setRoofColor(self.roofColor());

        let smallWallMaterial = self.smallWallMaterial();
        self.availableSmallWallColors().forEach(colorObject => {
          if (colorObject.value === self.smallWallColor()) {
            smallWallMaterial = colorObject.material;
          }
        });
        await configuratorScene.setSmallWallColor(self.smallWallColor(), smallWallMaterial);

        for (var element of self.facadeElements()) {
          element.id = await configuratorScene.addFacadeElement(element.element, element.positioningDirection, element.outLineDirection, element.colorCode, false);
        }

        self.inputEnvironmentalPermit(data["inputEnvironmentalPermit"]);
        self.inputDelivery(data["inputDelivery"]);
        self.inputFoundation(data["inputFoundation"]);
        self.inputSolarPanels(data["inputSolarPanels"]);
        self.inputGutter(data["inputGutter"]);
        configuratorScene.setGutter(self.inputGutter(), false);

        self.inputGender(data["inputGender"]);

        self.inputFirstName(data["inputFirstName"]);
        self.inputLastName(data["inputLastName"]);

        self.inputBusinessName(data["inputBusinessName"]);
        self.inputEmailAddress(data["inputEmailAddress"]);
        self.inputPhoneNumber(data["inputPhoneNumber"]);
        self.inputZipcode(data["inputZipcode"]);
        self.inputHouseNumber(data["inputHouseNumber"]);
        self.inputBuildLocationZipcode(data["inputBuildLocationZipcode"]);
        self.inputBuildLocationHouseNumber(data["inputBuildLocationHouseNumber"])
      } else {
        configuratorScene.initDefaultShed();
        self.wallTypeChanged(false, true);
        self.smallWallTypeChanged(false, true);
        self.roofTypeChanged(false, true);
        self.facadeElementChanged(false, true);
        self.gutterChanged(false);
        self.inputDeliveryChanged();
        self.shedTypeChanged();
      }

      await configuratorScene.updateScene();

      //await configuratorScene.getScreenshotImages();
    }

    this.inputDeliveryChanged = function () {
      self.availableFoundations.removeAll();
      for (var foundation of configuratorConfig.ValidFoundationsPerDelivery[self.inputDelivery()]) {
        self.availableFoundations.push({ "value": foundation.value, "text": foundation.text });
      }
    }

    var timer = null;
    this.sendEmailPriceEstimate = async function () {

      // check if valid
      if (self.errors().length > 0) {
        self.errors.showAllMessages();
        return;
      }

      //loading gif image
      var img = document.createElement("IMG");
      document.getElementById('btnSendQuote').innerHTML = "<img src='/dist/images/loading-buffering.gif'width='20px' height='20px' />";
      document.getElementById('message').innerHTML = "<img src='/dist/images/loading-buffering.gif'width='20px' height='20px' /> Uw aanvraag wordt verstuurd, een ogenblik aub.";

      if(timer == null){
        timer = setTimeout(async function(){
          self.images = ko.observableArray(await configuratorScene.getScreenshotImages());
        
          var jsonData = JSON.stringify(ko.toJSON(self));
          var xhr = new XMLHttpRequest();
          xhr.open("POST", configuratorScene.apiUrl + "/emailpriceestimate");
          xhr.setRequestHeader('X-Api-Key', '0e1973ce-e325-47e8-afc9-8b76ba5144ae');
          xhr.setRequestHeader('Content-Type', 'application/json');
        
          xhr.send(jsonData);

          xhr.onreadystatechange = function () {
            document.getElementById('btnSendQuote').innerHTML = "Offerte aanvragen";
            // Show error message
            let myMessageBox = document.getElementById('message') as HTMLInputElement;
            if (!xhr.status.toString().startsWith("2")) {
              myMessageBox.innerHTML = "Offerte is niet verstuurd probeer het later opnieuw!";
            }
            else {
              //email verzonden
              myMessageBox.innerHTML = "Dank voor uw aanvraag, de offerte ontvangt u binnen enkele minuten! (controleer uw spam)";
              localStorage.removeItem("configuratorData");
              window.location.href = "https://www.huisman.nl/hallenbouw/configurator/bedankt";
            }
            timer = null;
          }
        }, 50);
      }
    };
  };

  ko.validation.init({
    registerExtenders: true,
    messagesOnModified: true,
    insertMessages: true,
    parseInputAttributes: true,
    errorClass: "text-danger",
    messageTemplate: null

  }, true);
  ko.validation.locale("nl-NL");
  ko.options.deferUpdates = true;
  var vm = new viewModel("Warehouse", 35.0, "Size8", 3.0, 0, "LarssenSheetPiling", "CorrugatedIron", "None", "TwoSides");

  ko.applyBindings(vm);

  await vm.loadFromLocalStorage();

  // Start render loop.
  configuratorScene.doRender();
});
