import * as BABYLON from 'babylonjs';
import { PositioningDirection } from './Enums/positioningDirection';
import { OutlineDirection } from './Enums/outlineDirection';
import ShedMeshComposer from './shedMeshComposer';
import { PlaceableMeshType as PlaceableMeshType } from './Enums/placeableMeshes';
import ShedMeshSupplier from './shedMeshSupplier';
import { HighLightType } from './Enums/highlightType';
import FacadeElementBuilder from './facadeElementBuilder';

export default class FacadeElement {
    get OutlineDirection():OutlineDirection {
        return this.outlineDirection;
    }
    set OutlineDirection(value:OutlineDirection) {
        this.outlineDirection = value;
    }
    get PositioningDirection():PositioningDirection {
        return this.positionDirection;
    }
    set PositioningDirection(value:PositioningDirection) {
        this.positionDirection = value;
    }
    get PlaceableMeshType():PlaceableMeshType {
        return this.placeableMeshType;
    }
    set PlaceableMeshType(value:PlaceableMeshType) {
        this.placeableMeshType = value;
    }
    set BodyMeshName(value:string) {
        this.frameMeshName = value;
    }
    get BodyMeshName():string {
        return this.frameMeshName;
    }
    get HighLightType():HighLightType {
        return this.highLightType;
    }
    set Color(value:BABYLON.Color3){
        this.color = value;
    }
    get Color() : BABYLON.Color3 {
        return this.color;
    }

    private positionDirection:PositioningDirection;
    private outlineDirection:OutlineDirection
    private placeableMeshType:PlaceableMeshType;
    private frameMeshName:string = null;
    private highLightType:HighLightType = HighLightType.None;
    private color:BABYLON.Color3;

    id:number;

    SetHighLightType(highLightType:HighLightType, facadeElementBuilder:FacadeElementBuilder) {
        if(highLightType === HighLightType.Selected){
            for(const element of facadeElementBuilder.facadeElements){
                if(element.highLightType === HighLightType.Selected){
                    element.highLightType = HighLightType.None;
                }
            }
        }
        this.highLightType = highLightType;
    }

    GetDirectionVector() : BABYLON.Vector3 {
        var vec:BABYLON.Vector3 = null;
        switch(this.positionDirection){
            case PositioningDirection.Back:
                vec = BABYLON.Vector3.Forward();
                break;
            case PositioningDirection.Front:
                vec = BABYLON.Vector3.Backward();
                break;
            case PositioningDirection.Left:
                vec = BABYLON.Vector3.Left();
                break;
            case PositioningDirection.Right:
                vec = BABYLON.Vector3.Right();
                break;
        }
        return vec;
    }

    GetMargin() : number{
        return 0.2;
    }

    async GetMesh(shedMeshSupplier: ShedMeshSupplier, scene:BABYLON.Scene) : Promise<BABYLON.Mesh> {
        if(PlaceableMeshType[this.placeableMeshType].startsWith("Window")){
            return (await shedMeshSupplier.GetWindow(scene, this.placeableMeshType, false)) as BABYLON.Mesh;
        }
        if(PlaceableMeshType[this.placeableMeshType].startsWith("DoorEntry") || 
        PlaceableMeshType[this.placeableMeshType].startsWith("DoorRegular") ||
        PlaceableMeshType[this.placeableMeshType].startsWith("DoorRegularAlternative") ||
        PlaceableMeshType[this.placeableMeshType].startsWith("OverHeadDoorWindowed") || 
        PlaceableMeshType[this.placeableMeshType].startsWith("SlidingDoor") || 
        PlaceableMeshType[this.placeableMeshType].startsWith("SlidingDoubleDoor")){
            return (await shedMeshSupplier.GetDoor(scene, this.placeableMeshType)) as BABYLON.Mesh;
        }
    }
}