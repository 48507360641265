import * as BABYLON from 'babylonjs';
import { PerformanceMonitor } from 'babylonjs/Misc/performanceMonitor';
import SceneManager from './sceneManager';
import ShedMeshComposer from './shedMeshComposer';

export default class OptimizationManager {
    private shedMeshComposer: ShedMeshComposer;
    private sceneManager: SceneManager;
    private engine: BABYLON.Engine;
    private scene: BABYLON.Scene;
    private fpsMonitors: Array<number>;
    private lastFps:number;


    constructor(shedMeshComposer: ShedMeshComposer, sceneManager: SceneManager, engine: BABYLON.Engine, scene:BABYLON.Scene) {
        this.shedMeshComposer = shedMeshComposer;
        this.sceneManager = sceneManager;
        this.engine = engine;
        this.scene = scene;
        
        this.fpsMonitors = new Array<number>();
        let self = this;
        scene.onAfterCameraRenderObservable.add(function(){
            self.RemoveGrassBasedOnFPS();
        });
    }

    public RemoveGrassBasedOnFPS() {
        let currentFrameRate = this.engine.getFps();
        if(this.fpsMonitors.length < 30){
            this.fpsMonitors.push(currentFrameRate);
        }
        else {
            var total = 0;
            for(var i = 0; i < this.fpsMonitors.length; i++) {
                total += this.fpsMonitors[i];
            }
            var avg = total / this.fpsMonitors.length;
            if(avg < 30 && this.lastFps < 30) {
                let grassCount = this.shedMeshComposer.grassMeshes.length;
                for(let i = 0; i < 500; i++){
                    if(grassCount > 0){
                        let randomWholeNumber = Math.floor(Math.random() * grassCount);
                        this.shedMeshComposer.grassMeshes[randomWholeNumber].isVisible = false;
                    }
                }
                this.shedMeshComposer.disabledGrassCounter += 500;
            }
            this.lastFps = avg;
            this.fpsMonitors = new Array<number>();
        }
    }
}