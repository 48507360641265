import * as BABYLON from 'babylonjs';
import * as Materials from 'babylonjs-materials';
import { RoofType } from './Enums/roofType';
import { SmallWallType } from './Enums/smallWallType';
import { WallType } from './Enums/wallType';
import { TreeMaterial } from './Enums/treeMaterial';
import {default as concreteBSMVulcanRed} from '../Materials/Scene/ConcreteBrickVulcanRed.json'
import {default as concreteBSMRubyRed} from '../Materials/Scene/ConcreteBrickRubyRed.json'
import {default as concreteBSMVictoriaBlack} from '../Materials/Scene/ConcreteBrickVictoriaBlack.json'
import {default as concreteSilex} from '../Materials/Scene/ConcreteSilax.json'
import {default as concreteSmoothConcrete} from '../Materials/Scene/ConcreteConcrete.json'
import {default as roofDamPlate} from '../Materials/Scene/wallDamPlate.json'
import {default as roofGolfPlate} from '../Materials/Scene/roofGolfPlate.json'
import {default as roofSandwichGolf} from '../Materials/Scene/roofSandwichGolf.json'
import {default as roofSandwichTrapezium} from '../Materials/Scene/roofSandwichTrapezium.json'
import {default as trasRaam} from '../Materials/Scene/wallDamPlate.json'
import {default as wallDamPlate} from '../Materials/Scene/wallDamPlate.json'
import {default as wallPotLidPlate} from '../Materials/Scene/wallPotLidPlate.json'
import {default as wallPotLidPlateAndSandwichPlate} from '../Materials/Scene/wallPotLidPlateAndSandwichPlate.json'
import {default as wallSandwichPlate} from '../Materials/Scene/wallSandwichPlate.json'
import {default as spantMaterial} from '../Materials/Scene/SpantMaterial.json'
import {default as gutterMaterial} from '../Materials/Scene/GutterMaterial.json'
import {default as pillarMaterial} from '../Materials/Scene/PillarMaterial.json'
import {default as grassMaterial} from '../Materials/Scene/Grass.json'
import {default as grassModelMaterial} from '../Materials/Scene/Grass_Model.json'
import {default as metalBasicMaterial} from '../Materials/Scene/Metal_Basic.json'
import {default as plasticMaterial} from '../Materials/Scene/Plastic_Basic.json'
import {default as glassMaterial} from '../Materials/Scene/Glass_Basic.json'
import {default as groundTileMaterial} from '../Materials/Scene/GroundTile.json'
import {default as circularTreeMaterial} from '../Materials/Scene/Tree_Sprite.json'
import {default as treeMaterial1} from '../Materials/Scene/Tree_1.json'
import {default as treeMaterial2} from '../Materials/Scene/Tree_2.json'
import {default as treeMaterial3} from '../Materials/Scene/Tree_3.json'
import {default as bushMaterial} from '../Materials/Scene/Bush.json'
import ShedMeshComposer from './shedMeshComposer';
import { SmallWallMaterial } from './Enums/smallWallMaterial';

export default class ShedMaterialSupplier {
    private testMaterial:BABYLON.Material;

    private smallWallMaterialConcreteBSMVulcanRed:BABYLON.PBRMaterial;
    private smallWallMaterialConcreteBSMRubyRed:BABYLON.PBRMaterial;
    private smallWallMaterialConcreteBSMVictoriaBlack:BABYLON.PBRMaterial;
    private smallWallMaterialConcreteSmoothConcrete:BABYLON.PBRMaterial;
    private smallWallMaterialConcreteSilex:BABYLON.PBRMaterial;
    private smallWallMaterialTrasRaam:BABYLON.PBRMaterial;

    private wallMaterialDamPlate:BABYLON.Material;
    private wallMaterialPotLidPlate:BABYLON.Material;
    private wallMaterialSandwichPlate:BABYLON.Material;

    private wallNoBumpMaterialDamPlate:BABYLON.Material;
    private wallNoBumpMaterialSandwichPlate:BABYLON.Material;

    private roofMaterialDamPlate:BABYLON.Material;
    private roofMaterialGolfPlate:BABYLON.Material;
    private roofMaterialSandwichGolf:BABYLON.Material;
    private roofMaterialSandwichTrapezium:BABYLON.Material;

    private gutterMaterial:BABYLON.Material;
    private spantMaterial:BABYLON.Material;
    private pillarMaterial:BABYLON.Material;
    
    private grassMaterial:BABYLON.Material;
    private grassModelMaterial:BABYLON.Material;
    private metalBasicMaterial:BABYLON.Material;

    private plasticMaterial:BABYLON.Material;
    private glassMaterial:BABYLON.Material;

    private groundTileMaterial:BABYLON.StandardMaterial;

    private circularTreeMaterial:BABYLON.Material;

    private treeMaterials:Array<BABYLON.Material>;

    private bushMaterial:BABYLON.StandardMaterial;

    private scene:BABYLON.Scene;

    constructor(scene: BABYLON.Scene) {
        this.scene = scene;
    }
    
    public async LoadMaterials(scene:BABYLON.Scene) : Promise<void> {
        this.smallWallMaterialConcreteBSMVulcanRed = BABYLON.PBRMaterial.Parse(concreteBSMVulcanRed, scene, "./../Materials/Scene/"); 
        this.smallWallMaterialConcreteBSMRubyRed = BABYLON.PBRMaterial.Parse(concreteBSMRubyRed, scene, "./../Materials/Scene/"); 
        this.smallWallMaterialConcreteBSMVictoriaBlack = BABYLON.PBRMaterial.Parse(concreteBSMVictoriaBlack, scene, "./../Materials/Scene/"); 
        this.smallWallMaterialConcreteSmoothConcrete = BABYLON.PBRMaterial.Parse(concreteSmoothConcrete, scene, "./../Materials/Scene/"); 
        this.smallWallMaterialConcreteSilex = BABYLON.PBRMaterial.Parse(concreteSilex, scene, "./../Materials/Scene/"); 

        this.smallWallMaterialTrasRaam = BABYLON.PBRMaterial.Parse(trasRaam, scene, "./../Materials/Scene/"); 
        this.smallWallMaterialTrasRaam.freeze();

        this.wallMaterialDamPlate = BABYLON.PBRMaterial.Parse(wallDamPlate, scene, "./../Materials/Scene/"); 
        this.wallMaterialDamPlate.freeze();
        this.wallMaterialPotLidPlate = BABYLON.PBRMaterial.Parse(wallPotLidPlate, scene, "./../Materials/Scene/"); 
        this.wallMaterialPotLidPlate.freeze();
        this.wallMaterialSandwichPlate = BABYLON.PBRMaterial.Parse(wallSandwichPlate, scene, "./../Materials/Scene/"); 
        this.wallMaterialSandwichPlate.freeze();

        this.wallNoBumpMaterialDamPlate = BABYLON.PBRMaterial.Parse(wallDamPlate, scene, "./../Materials/Scene/"); 
        (this.wallNoBumpMaterialDamPlate as BABYLON.PBRMaterial).bumpTexture = null;
        this.wallNoBumpMaterialDamPlate.freeze();
        this.wallNoBumpMaterialSandwichPlate = BABYLON.PBRMaterial.Parse(wallSandwichPlate, scene, "./../Materials/Scene/"); 
        (this.wallNoBumpMaterialSandwichPlate as BABYLON.PBRMaterial).bumpTexture = null;
        this.wallNoBumpMaterialSandwichPlate.freeze();

        this.roofMaterialDamPlate = BABYLON.PBRMaterial.Parse(roofDamPlate, scene, "./../Materials/Scene/"); 
        (this.roofMaterialDamPlate as BABYLON.PBRMaterial).bumpTexture = null;
        this.roofMaterialDamPlate.freeze();
        this.roofMaterialGolfPlate = BABYLON.PBRMaterial.Parse(roofGolfPlate, scene, "./../Materials/Scene/"); 
        this.roofMaterialGolfPlate.freeze();
        this.roofMaterialSandwichGolf = BABYLON.PBRMaterial.Parse(roofSandwichGolf, scene, "./../Materials/Scene/"); 
        this.roofMaterialSandwichGolf.freeze();
        this.roofMaterialSandwichTrapezium = BABYLON.PBRMaterial.Parse(roofSandwichTrapezium, scene, "./../Materials/Scene/"); 
        this.roofMaterialSandwichTrapezium.freeze();

        this.gutterMaterial = BABYLON.PBRMaterial.Parse(gutterMaterial, scene, "./../Materials/Scene/"); 
        this.gutterMaterial.freeze();
        this.pillarMaterial = BABYLON.PBRMaterial.Parse(pillarMaterial, scene, "./../Materials/Scene/"); 
        this.pillarMaterial.freeze();
        this.spantMaterial = BABYLON.PBRMaterial.Parse(spantMaterial, scene, "./../Materials/Scene/"); 
        this.spantMaterial.freeze();

        this.grassMaterial = BABYLON.StandardMaterial.Parse(grassMaterial, scene, "./../Materials/Scene/");
        this.grassMaterial.freeze();
        
        this.grassModelMaterial = BABYLON.StandardMaterial.Parse(grassModelMaterial, scene, "./../Materials/Scene/");
        this.grassModelMaterial.freeze();

        this.metalBasicMaterial = BABYLON.PBRMaterial.Parse(metalBasicMaterial, scene, "./../Materials/Scene/");
        this.metalBasicMaterial.freeze();

        this.plasticMaterial = BABYLON.PBRMaterial.Parse(plasticMaterial, scene, "./../Materials/Scene/");
        this.plasticMaterial.freeze();
        
        this.glassMaterial = BABYLON.PBRMaterial.Parse(glassMaterial, scene, "./../Materials/Scene/");
        this.glassMaterial.freeze();
        
        this.groundTileMaterial = BABYLON.StandardMaterial.Parse(groundTileMaterial, scene, "./../Materials/Scene/");
        //this.groundTileMaterial.freeze(); do not uncomment, this causes the floor to display differently initially

        this.circularTreeMaterial = BABYLON.PBRMaterial.Parse(circularTreeMaterial, scene, "./../Materials/Scene/");
        this.circularTreeMaterial.freeze();

        this.treeMaterials = new Array<BABYLON.Material>();
        let treeMaterial_1 = BABYLON.StandardMaterial.Parse(treeMaterial1, scene, "./../Materials/Scene/");
        treeMaterial_1.freeze();
        this.treeMaterials.push(treeMaterial_1);

        let treeMaterial_2 = BABYLON.StandardMaterial.Parse(treeMaterial2, scene, "./../Materials/Scene/");
        treeMaterial_2.freeze();
        this.treeMaterials.push(treeMaterial_2);

        let treeMaterial_3 = BABYLON.StandardMaterial.Parse(treeMaterial3, scene, "./../Materials/Scene/");
        treeMaterial_3.freeze();
        this.treeMaterials.push(treeMaterial_3);

        this.bushMaterial = BABYLON.StandardMaterial.Parse(bushMaterial, scene, "./../Materials/Scene/");
        this.bushMaterial.freeze();
    }

    
    public UpdateSmallWallMaterialUVs(shedMeshComposer:ShedMeshComposer, smallWallType:SmallWallType, uvScale:number, smallWallMaterial: SmallWallMaterial){
        switch(smallWallType){
            case SmallWallType.Concrete:
            case SmallWallType.ConcreteIsolated:
                switch(smallWallMaterial){
                    case SmallWallMaterial.BSMRubyRed:
                    case SmallWallMaterial.BSMVulcanRed:
                    case SmallWallMaterial.BSMVictoriaBlack:
                        let concreteMaterial = this.getSmallWallConcrete(smallWallMaterial);
                        concreteMaterial.unfreeze();
                        ((concreteMaterial as BABYLON.PBRMaterial).albedoTexture as BABYLON.Texture).vScale = uvScale;
                        shedMeshComposer.unfrozenMaterialNames.push(concreteMaterial.name);
                    case SmallWallMaterial.Silex:
                        let silexMaterial = this.GetSmallWallMaterial(smallWallType, SmallWallMaterial.Silex);
                        silexMaterial.unfreeze();
                        ((silexMaterial as BABYLON.PBRMaterial).albedoTexture as BABYLON.Texture).vScale = uvScale * 5;
                        ((silexMaterial as BABYLON.PBRMaterial).albedoTexture as BABYLON.Texture).uScale = uvScale * 5;
                        shedMeshComposer.unfrozenMaterialNames.push(silexMaterial.name);
                        break;
                }
                break;
            case SmallWallType.DampProof:
                    let dampProofMaterial = this.GetSmallWallMaterial(smallWallType, SmallWallMaterial.Silex);
                    dampProofMaterial.unfreeze();
                    if(shedMeshComposer.Width % 2 == 1) {
                        ((dampProofMaterial as BABYLON.PBRMaterial).bumpTexture as BABYLON.Texture).uOffset = 0.5;
                    }
                    else{
                        ((dampProofMaterial as BABYLON.PBRMaterial).bumpTexture as BABYLON.Texture).uOffset = 0;
                    }
                    shedMeshComposer.unfrozenMaterialNames.push(dampProofMaterial.name);
                break;
        }
    }

    private getSmallWallConcrete(smallWallMaterial:SmallWallMaterial):BABYLON.PBRMaterial {
        switch(smallWallMaterial){
            case SmallWallMaterial.BSMVulcanRed:
                return this.smallWallMaterialConcreteBSMVulcanRed;
                break;
            case SmallWallMaterial.BSMRubyRed:
                return this.smallWallMaterialConcreteBSMRubyRed;
                break;
            case SmallWallMaterial.BSMVictoriaBlack:
                return this.smallWallMaterialConcreteBSMVictoriaBlack;
                break;
            case SmallWallMaterial.SmoothConcrete:
                return this.smallWallMaterialConcreteSmoothConcrete;
                break;
            case SmallWallMaterial.Silex:
                return this.smallWallMaterialConcreteSilex;
                break;
        }
    }

    public GetSmallWallMaterial(smallWallType:SmallWallType, smallWallMaterial:SmallWallMaterial):BABYLON.Material{
        switch(smallWallType) {
            case SmallWallType.Concrete:
            case SmallWallType.ConcreteIsolated:
                return this.getSmallWallConcrete(smallWallMaterial);
            case SmallWallType.DampProof:
                return this.smallWallMaterialTrasRaam;
        }
    }

    public GetWallMaterial(wallType:WallType):BABYLON.Material{
        switch(wallType){
            case WallType.LarssenSheetPiling:
                return this.wallMaterialDamPlate;
            case WallType.Clapboard:
                return this.wallMaterialPotLidPlate;
            case WallType.ClapboardAndSandwichPanel:
                return this.wallMaterialPotLidPlate;
            case WallType.SandwichPanel:
                return this.wallMaterialSandwichPlate;
        }
    }

    public GetWallMaterialColoured(wallType:WallType, color:BABYLON.Color3, bump:boolean):BABYLON.Material{
        let material = this.GetWallMaterial(wallType).clone(wallType.toString() + color.toString());
        (material as BABYLON.PBRMaterial).albedoColor = color;
        if(!bump){
            (material as BABYLON.PBRMaterial).bumpTexture = null;
        }
        return material;
    }

    public GetWallWithoutBumpMaterial(wallType:WallType):BABYLON.Material{
        switch(wallType){
            case WallType.LarssenSheetPiling:
                return this.wallNoBumpMaterialDamPlate;
            case WallType.Clapboard:
                return this.wallMaterialPotLidPlate;
            case WallType.ClapboardAndSandwichPanel:
                return this.wallMaterialPotLidPlate;
            case WallType.SandwichPanel:
                return this.wallNoBumpMaterialSandwichPlate;
        }
    }
    
    public GetRoofMaterial():BABYLON.Material{
        return this.roofMaterialDamPlate;
    }
    public GetPannelMaterial(wallType:WallType):BABYLON.Material{
        return this.GetWallMaterial(wallType);
    }
    public GetPillarMaterial():BABYLON.Material{
        return null;
    }
    public GetGutterMaterial():BABYLON.Material{
        return this.gutterMaterial;
    }
    public GetTestMaterial():BABYLON.Material{
        return this.testMaterial;
    }
    public GetSpantMaterial():BABYLON.Material{
        return this.spantMaterial;
    }
    public GetGrassMaterial():BABYLON.Material{
        return this.grassMaterial;
    }
    public GetGrassModelMaterial():BABYLON.Material{
        return this.grassModelMaterial;
    }
    public GetMetalBasicMaterial():BABYLON.Material{
        return this.metalBasicMaterial;
    }
    public GetPlasticMaterial():BABYLON.Material{
        return this.plasticMaterial;
    }
    public GetGlassMaterial():BABYLON.Material{
        return this.glassMaterial;
    }
    public GetCircularTreeMaterial():BABYLON.Material{
        return this.circularTreeMaterial;
    }
    public GetTreeMaterial(treeMaterial:TreeMaterial):BABYLON.Material{
        return this.treeMaterials[treeMaterial-1];
    }
    public GetBushMaterial():BABYLON.Material{
        return this.bushMaterial;
    }
    public GetGroundTileMaterial():BABYLON.Material{
        return this.groundTileMaterial;
    }
}