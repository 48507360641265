import * as BABYLON from 'babylonjs';

export default class MeshCutter {
    static sliceMesh(mesh:BABYLON.Mesh, positions:BABYLON.Vector3[], scene:BABYLON.Scene) : BABYLON.Mesh {
        positions.push(positions[0]);  //close profile
        var direction = [
            new BABYLON.Vector3(0, 0, 0),
            new BABYLON.Vector3(0, 0, 1)
        ];
        var triangle = BABYLON.MeshBuilder.ExtrudeShape("ExtrusionShape",  {shape: positions, path: direction, cap: BABYLON.Mesh.CAP_ALL, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, scene);
        triangle.position = new BABYLON.Vector3(0, 0, -0.5);
        mesh.setAbsolutePosition(new BABYLON.Vector3(0, 0,0));
        
        var meshCSG = BABYLON.CSG.FromMesh(mesh);
        var slicerCSG = BABYLON.CSG.FromMesh(triangle);
        
        var newMesh = meshCSG.intersect(slicerCSG).buildMeshGeometry(mesh.name + "_Cut", scene);
        newMesh.createNormals(false);
        triangle.dispose();
        newMesh.material = mesh.material;
        return newMesh;
    }
}
