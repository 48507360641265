import * as BABYLON from 'babylonjs';
import * as Materials from 'babylonjs-materials';
import { default as sun } from "../lights/sun.json";
import { default as upperHemisphere } from "../lights/upperHemisphere.json";
import { default as lowerHemisphere } from "../lights/lowerHemisphere.json";
import { default as sunShadow } from "../shadows/sun.json";
import { default as skyMaterial } from "../Materials/Scene/sky.json";
import ShedMeshComposer from './shedMeshComposer';
import { TreeMaterial } from './Enums/treeMaterial';
import ShedMeshSupplier from './shedMeshSupplier';
import ShedMaterialSupplier from './shedMaterialSupplier';
import { BabylonFileLoaderConfiguration } from 'babylonjs/Loading/Plugins/babylonFileLoader';
import UtilityFunctions from './utilityFunctions';

export default class SceneManager {
    public sun: BABYLON.Light;
    public upperHemisphere: BABYLON.HemisphericLight;
    public lowerHemisphere: BABYLON.HemisphericLight;
    public camera: BABYLON.ArcRotateCamera;
    public skyBox: BABYLON.Mesh;
    public instancedTrees: BABYLON.InstancedMesh[];
    public defaultPipeline: BABYLON.DefaultRenderingPipeline = null;

    public async BuildScene(engine: BABYLON.Engine, shedMeshSupplier: ShedMeshSupplier, scene: BABYLON.Scene, canvas: HTMLCanvasElement, generateShadows: boolean) {
        scene.environmentTexture = new BABYLON.CubeTexture("./../environments/environment.env", scene);
        scene.environmentIntensity = 0.1;
        scene.postProcessesEnabled = true;

        scene.autoClear = false; // Color buffer
        scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously

        this.camera = new BABYLON.ArcRotateCamera('camera', 0, 0, 20, new BABYLON.Vector3(0, 1, 0), scene);
        (this.camera as BABYLON.Camera).position = new BABYLON.Vector3(10, 10, -40);
        this.camera.lowerRadiusLimit = 10;
        this.camera.upperRadiusLimit = 80;
        this.camera.upperBetaLimit = Math.PI / 2.0 - 0.05;
        this.camera.wheelPrecision = 5;
        this.camera.angularSensibilityX = 125000 / Math.min(500, 2000 - canvas.width);
        this.camera.angularSensibilityY = 250000 / Math.min(500, 2000 - canvas.height);
        this.camera.inertia = 0.3;

        // Hacky way of dissabling the right mouse button
        (this.camera.inputs.attached.pointers as any).buttons = [0];
        // Attach the camera to the canvas.
        this.camera.attachControl(canvas, false);

        this.configurePostProcesses(scene, this.camera);
        engine.flushFramebuffer();
        scene.render();

        this.sun = BABYLON.Light.Parse(sun, scene);
        if (generateShadows) {
            this.sun._shadowGenerator = BABYLON.ShadowGenerator.Parse(sunShadow, scene);
        }
        this.sun.intensity = 1;

        this.upperHemisphere = new BABYLON.HemisphericLight("HemiLight", new BABYLON.Vector3(0, -1, 0), scene);
        this.upperHemisphere.intensity = 5;
        this.upperHemisphere.groundColor = new BABYLON.Color3(1, 1, 1);

        BABYLON.SceneLoader.ShowLoadingScreen = false;

        var dome = new BABYLON.PhotoDome(
            "testdome",
            "./../Materials/Scene/files/Sky_Environment.jpg",
            {
                resolution: 32,
                size: 1500
            },
            scene
        );

    }

    BuildEnvironment(shedMeshSupplier: ShedMeshSupplier, shedMaterialSupplier: ShedMaterialSupplier, scene: BABYLON.Scene) {
        //Create a built-in "ground" shape.
        let ground = BABYLON.MeshBuilder.CreatePlane('ground1', { width: 1000, height: 1000 }, scene);
        ground.setDirection(BABYLON.Vector3.Down());
        ground.material = shedMaterialSupplier.GetGrassMaterial();
        if (!UtilityFunctions.detectAndroid()) {
            ground.receiveShadows = true;
        }
        ground.freezeWorldMatrix();

        var treeCircular = (shedMeshSupplier.GetTreeCircular(scene) as BABYLON.Mesh);
        var treeCircularInstance = treeCircular.createInstance("circularTree");
        treeCircularInstance.scaling = new BABYLON.Vector3(5, 5, 5);
        treeCircularInstance.freezeWorldMatrix();
        this.BuildTrees(shedMeshSupplier, scene);
    }

    private BuildTrees(shedMeshSupplier: ShedMeshSupplier, scene: BABYLON.Scene) {
        let trees = new Array<BABYLON.Mesh>();
        this.instancedTrees = new Array<BABYLON.InstancedMesh>();
        trees.push(shedMeshSupplier.GetTree(scene, TreeMaterial.One) as BABYLON.Mesh);
        trees.push(shedMeshSupplier.GetTree(scene, TreeMaterial.Two) as BABYLON.Mesh);
        trees.push(shedMeshSupplier.GetTree(scene, TreeMaterial.Three) as BABYLON.Mesh);
        trees.push(shedMeshSupplier.GetBush(scene) as BABYLON.Mesh);
        let r = 90;
        for (let i = 0; i < Math.PI * 2; i += (0.015 + Math.random() / 1000)) {
            let tempRadius = r + Math.random() * 150;
            let coordinate = new BABYLON.Vector2(tempRadius * Math.cos(i), tempRadius * Math.sin(i));
            let tree = trees[Math.floor((Math.random() * trees.length)) % trees.length].createInstance("Tree");
            tree.position = new BABYLON.Vector3(coordinate.x, 0, coordinate.y);
            var random = Math.random();
            tree.scaling.y = 3 * random + 2 + Math.random() * 1;
            tree.scaling.x = 3 * random + 2 + Math.random() * 1;
            tree.scaling.z = 3 * random + 2 + Math.random() * 1;
            tree.setDirection(BABYLON.Vector3.Zero().subtract(tree.position).normalize());
            tree.freezeWorldMatrix();
            this.instancedTrees.push(tree);
        }
    }

    public UpdateCamera(shedMeshComposer: ShedMeshComposer) {
        var width = shedMeshComposer.Width / 2.0;
        var length = shedMeshComposer.Length / 2.0;
        var radius = Math.sqrt(width * width + length * length);

        this.camera.lowerRadiusLimit = radius + 2;
    }

    ///Returns base64 strings representing .png images of all sides of the barn
    public async CreateSceneRenderFiles(shedMeshComposer: ShedMeshComposer, scene: BABYLON.Scene, engine: BABYLON.Engine): Promise<Array<string>> {
        let images = new Array<string>();

        let lookAtPosition = shedMeshComposer.ShedPosition.add(new BABYLON.Vector3(0, shedMeshComposer.Height, 0));
        let screenshotWidth = 512;
        let screenshotHeight = 360;
        let cameraHeight = shedMeshComposer.Height;
        let cameraDistance: BABYLON.Vector2 = new BABYLON.Vector2(shedMeshComposer.Width / 2 + shedMeshComposer.Length * 1, shedMeshComposer.Length / 2 + shedMeshComposer.Width * 1);

        let averageDistance = shedMeshComposer.Length / 2 + shedMeshComposer.Width * 1.2;

        let diagonalPosition = new BABYLON.Vector3(shedMeshComposer.Width, 6.5, -averageDistance);
        let camera0 = new BABYLON.ArcRotateCamera('camera', 0, 0, 20, lookAtPosition.add(new BABYLON.Vector3(-shedMeshComposer.Width / 2, -shedMeshComposer.Height / 2, 0)), scene);
        camera0.position = diagonalPosition;

        this.instancedTrees.forEach((tree) => {
            tree.isVisible = BABYLON.Vector3.Dot(tree.position.negate(), camera0.position.negate()) < 0;
        });

        this.configurePostProcesses(scene, camera0);
        engine.flushFramebuffer();
        scene.render();
        var data = await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(engine, camera0, { width: screenshotWidth * 2, height: screenshotHeight * 2 }, "image/jpeg");
        this.defaultPipeline.removeCamera(camera0);
        camera0.dispose();
        images.push(data);

        //document.getElementById("cardimage").setAttribute("src", data);

        let frontPosition = new BABYLON.Vector3(0, cameraHeight, -1 * cameraDistance.y);
        let camera1 = new BABYLON.ArcRotateCamera('camera', 0, 0, 20, lookAtPosition, scene);
        camera1.position = frontPosition;

        this.instancedTrees.forEach((tree) => {
            tree.isVisible = BABYLON.Vector3.Dot(tree.position.negate(), camera1.position.negate()) < 0;
        });

        this.configurePostProcesses(scene, camera1);
        engine.flushFramebuffer();
        scene.render();

        var data = await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(engine, camera1, { width: screenshotWidth, height: screenshotHeight }, "image/jpeg");
        this.defaultPipeline.removeCamera(camera1);
        camera1.dispose();
        images.push(data);

        let leftPosition = new BABYLON.Vector3(-1 * cameraDistance.x, cameraHeight, 0);
        let camera2 = new BABYLON.ArcRotateCamera('camera', 0, 0, 20, lookAtPosition, scene);
        camera2.position = leftPosition;

        this.instancedTrees.forEach((tree) => {
            tree.isVisible = BABYLON.Vector3.Dot(tree.position.negate(), camera2.position.negate()) < 0;
        });

        this.configurePostProcesses(scene, camera2);
        engine.flushFramebuffer();
        scene.render();

        var data = await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(engine, camera2, { width: screenshotWidth, height: screenshotHeight }, "image/jpeg");
        this.defaultPipeline.removeCamera(camera2);
        camera2.dispose();
        images.push(data);

        let backPosition = new BABYLON.Vector3(0, cameraHeight, cameraDistance.y);
        let camera3 = new BABYLON.ArcRotateCamera('camera', 0, 0, 20, lookAtPosition, scene);
        camera3.position = backPosition;

        this.instancedTrees.forEach((tree) => {
            tree.isVisible = BABYLON.Vector3.Dot(tree.position.negate(), camera3.position.negate()) < 0;
        });

        this.configurePostProcesses(scene, camera3);
        engine.flushFramebuffer();
        scene.render();

        var data = await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(engine, camera3, { width: screenshotWidth, height: screenshotHeight }, "image/jpeg");
        this.defaultPipeline.removeCamera(camera3);
        camera3.dispose();
        images.push(data);

        let rightPosition = new BABYLON.Vector3(cameraDistance.x, cameraHeight, 0);
        let camera4 = new BABYLON.ArcRotateCamera('camera', 0, 0, 20, lookAtPosition, scene);
        camera4.position = rightPosition;

        this.instancedTrees.forEach((tree) => {
            tree.isVisible = BABYLON.Vector3.Dot(tree.position.negate(), camera4.position.negate()) < 0;
        });

        this.configurePostProcesses(scene, camera4);
        engine.flushFramebuffer();
        scene.render();

        var data = await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(engine, camera4, { width: screenshotWidth, height: screenshotHeight }, "image/jpeg");
        this.defaultPipeline.removeCamera(camera4);
        camera4.dispose();
        images.push(data);

        this.instancedTrees.forEach((tree) => {
            tree.isVisible = true;
        });

        engine.flushFramebuffer();
        scene.render();

        return images;
    }

    configurePostProcesses(scene: BABYLON.Scene, camera: BABYLON.Camera): BABYLON.DefaultRenderingPipeline {
        let ambientOcclusionEnabled = false;
        if (ambientOcclusionEnabled) {
            var ssao = new BABYLON.SSAO2RenderingPipeline("ssao", scene, {
                ssaoRatio: 0.5, // Ratio of the SSAO post-process, in a lower resolution
                blurRatio: 1 // Ratio of the combine post-process (combines the SSAO and the scene)
            });
            ssao.radius = 8;
            ssao.totalStrength = 0.9;
            ssao.expensiveBlur = true;
            ssao.samples = 16;
            ssao.maxZ = 100;
            scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao", camera);
        }

        if(this.defaultPipeline === null){
            this.defaultPipeline = new BABYLON.DefaultRenderingPipeline(
                "DefaultRenderingPipeline",
                true, // is HDR?
                scene,
                [camera]
            );

            if (this.defaultPipeline.isSupported) {
                this.defaultPipeline.samples = 4;
    
                /* imageProcessing */
                this.defaultPipeline.imageProcessingEnabled = true; //true by default
                if (this.defaultPipeline.imageProcessingEnabled) {
                    this.defaultPipeline.imageProcessing.contrast = 1; // 1 by default
                    this.defaultPipeline.imageProcessing.exposure = 1; // 1 by default
    
                    /* color grading */
                    this.defaultPipeline.imageProcessing.colorGradingEnabled = false; // false by default
                    if (this.defaultPipeline.imageProcessing.colorGradingEnabled) {
                        // using .3dl (best) :
                        this.defaultPipeline.imageProcessing.colorGradingTexture = new BABYLON.ColorGradingTexture("textures/LateSunset.3dl", scene);
                        // using .png :
                        /*
                        var colorGradingTexture = new BABYLON.Texture("textures/colorGrade-highContrast.png", scene, true, false);
                        colorGradingTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;
                        colorGradingTexture.wrapV = BABYLON.Texture.CLAMP_ADDRESSMODE;                
                        defaultPipeline.imageProcessing.colorGradingTexture = colorGradingTexture;
                        defaultPipeline.imageProcessing.colorGradingWithGreenDepth = false;
                        */
                    }
                    /* color curves */
                    this.defaultPipeline.imageProcessing.colorCurvesEnabled = false; // false by default
                    if (this.defaultPipeline.imageProcessing.colorCurvesEnabled) {
                        var curve = new BABYLON.ColorCurves();
                        curve.globalDensity = 0; // 0 by default
                        curve.globalExposure = 0; // 0 by default
                        curve.globalHue = 30; // 30 by default
                        curve.globalSaturation = 0; // 0 by default
                        curve.highlightsDensity = 0; // 0 by default
                        curve.highlightsExposure = 0; // 0 by default
                        curve.highlightsHue = 30; // 30 by default
                        curve.highlightsSaturation = 0; // 0 by default
                        curve.midtonesDensity = 0; // 0 by default
                        curve.midtonesExposure = 0; // 0 by default
                        curve.midtonesHue = 30; // 30 by default
                        curve.midtonesSaturation = 0; // 0 by default
                        curve.shadowsDensity = 0; // 0 by default
                        curve.shadowsExposure = 0; // 0 by default
                        curve.shadowsHue = 30; // 30 by default
                        curve.shadowsDensity = 80;
                        curve.shadowsSaturation = 0; // 0 by default;
                        this.defaultPipeline.imageProcessing.colorCurves = curve;
                    }
                }
                /* bloom */
                this.defaultPipeline.bloomEnabled = false; // false by default
                if (this.defaultPipeline.bloomEnabled) {
                    this.defaultPipeline.bloomKernel = 64; // 64 by default
                    this.defaultPipeline.bloomScale = 0.5; // 0.5 by default
                    this.defaultPipeline.bloomThreshold = 0.9; // 0.9 by default
                    this.defaultPipeline.bloomWeight = 0.15; // 0.15 by default
                }
                /* chromatic abberation */
                this.defaultPipeline.chromaticAberrationEnabled = false; // false by default
                if (this.defaultPipeline.chromaticAberrationEnabled) {
                    this.defaultPipeline.chromaticAberration.aberrationAmount = 30; // 30 by default
                    this.defaultPipeline.chromaticAberration.adaptScaleToCurrentViewport = false; // false by default
                    this.defaultPipeline.chromaticAberration.alphaMode = 0; // 0 by default
                    this.defaultPipeline.chromaticAberration.alwaysForcePOT = false; // false by default
                    this.defaultPipeline.chromaticAberration.enablePixelPerfectMode = false; // false by default
                    this.defaultPipeline.chromaticAberration.forceFullscreenViewport = true; // true by default
                }
                /* DOF */
                this.defaultPipeline.depthOfFieldEnabled = false; // false by default
                if (this.defaultPipeline.depthOfFieldEnabled && this.defaultPipeline.depthOfField.isSupported) {
                    this.defaultPipeline.depthOfFieldBlurLevel = 0; // 0 by default
                    this.defaultPipeline.depthOfField.fStop = 1.4; // 1.4 by default
                    this.defaultPipeline.depthOfField.focalLength = 50; // 50 by default, mm
                    this.defaultPipeline.depthOfField.focusDistance = 2000; // 2000 by default, mm
                    this.defaultPipeline.depthOfField.lensSize = 50; // 50 by default
                }
                /* FXAA */
                this.defaultPipeline.fxaaEnabled = true; // false by default
                if (this.defaultPipeline.fxaaEnabled) {
                    this.defaultPipeline.fxaa.samples = 1; // 1 by default
                    this.defaultPipeline.fxaa.adaptScaleToCurrentViewport = false; // false by default 
                }
                /* glowLayer */
                this.defaultPipeline.glowLayerEnabled = false;
                if (this.defaultPipeline.glowLayerEnabled) {
                    this.defaultPipeline.glowLayer.blurKernelSize = 16; // 16 by default
                    this.defaultPipeline.glowLayer.intensity = 1; // 1 by default
                }
                /* grain */
                this.defaultPipeline.grainEnabled = false;
                if (this.defaultPipeline.grainEnabled) {
                    this.defaultPipeline.grain.adaptScaleToCurrentViewport = false; // false by default
                    this.defaultPipeline.grain.animated = false; // false by default
                    this.defaultPipeline.grain.intensity = 30; // 30 by default
                }
                /* MSAA */
                this.defaultPipeline.samples = 1; // 1 by default
                /* sharpen */
                this.defaultPipeline.sharpenEnabled = false;
                if (this.defaultPipeline.sharpenEnabled) {
                    this.defaultPipeline.sharpen.adaptScaleToCurrentViewport = false; // false by default
                    this.defaultPipeline.sharpen.edgeAmount = 0.3; // 0.3 by default
                    this.defaultPipeline.sharpen.colorAmount = 1; // 1 by default
                }
            }
        }
        else{
            this.defaultPipeline.addCamera(camera);
        }

        return this.defaultPipeline;
    }
}
