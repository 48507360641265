import * as BABYLON from 'babylonjs';
import { BabylonFileLoaderConfiguration, _BabylonLoaderRegistered } from 'babylonjs/Loading/Plugins/babylonFileLoader';
import ShedMeshSupplier from './shedMeshSupplier';
import { Width } from './Enums/width';
import { ShedType } from './Enums/shedType';
import { SmallWallType } from './Enums/smallWallType';
import { WallType } from './Enums/wallType';
import { RoofType } from './Enums/roofType';
import FacadeElement from './facadeElement';
import { PositioningDirection } from './Enums/positioningDirection';
import { OutlineDirection } from './Enums/outlineDirection';
import ShedMaterialSupplier from './shedMaterialSupplier';
import { PlaceableMeshType } from './Enums/placeableMeshes';
import FacadeElementBuilder from './facadeElementBuilder';
import UtilityFunctions from './utilityFunctions';
import { GutterOption } from './Enums/gutterOption';
import { SmallWallMaterial } from './Enums/smallWallMaterial';
import SceneManager from './sceneManager';

export default class ShedMeshComposer {

    get Width(): number {
        return this.width;
    }
    set Width(value: number) {
        this.sizeChanged = true;
        this.width = value;
    }
    get Length(): number {
        return this.length;
    }
    set Length(value: number) {
        this.sizeChanged = true;
        this.length = value;
    }
    get Height(): number {
        return this.height;
    }
    set Height(value: number) {
        this.height = value;
    }
    get GutterOption(): GutterOption {
        return this.hasGutter;
    }
    set GutterOption(value: GutterOption) {
        this.hasGutter = value;
    }
    get ShedType(): ShedType {
        return this.barnType;
    }
    set ShedType(value: ShedType) {
        this.barnType = value;
    }
    get SmallWallHeight(): number {
        if (this.smallWallType === SmallWallType.None) {
            return 0.0;
        }
        return this.smallWallHeight;
    }
    set SmallWallHeight(value: number) {
        this.smallWallHeight = value;
    }
    get SmallWallType(): SmallWallType {
        return this.smallWallType;
    }
    set SmallWallType(value: SmallWallType) {
        this.smallWallType = value;
    }
    get WallType(): WallType {
        return this.wallType;
    }
    set WallType(value: WallType) {
        this.wallType = value;
    }
    get RoofType(): RoofType {
        return this.roofType;
    }
    set RoofType(value: RoofType) {
        this.roofType = value;
    }
    get ShedPosition(): BABYLON.Vector3 {
        return this.shedPosition;
    }
    set SmallWallMaterial(value:SmallWallMaterial) {
        this.smallWallMaterial = value;
    }
    get SmallWallMaterial(): SmallWallMaterial {
        return this.smallWallMaterial;
    }
    private GetLeftBottomTilePosition(): BABYLON.Vector3 {
        return this.shedPosition.subtract(new BABYLON.Vector3(this.width / 2.0, 0, this.length / 2.0)).add(new BABYLON.Vector3(0.5, 0, 0.5));
    }
    private GetRightBackTilePosition(): BABYLON.Vector3 {
        return this.shedPosition.add(new BABYLON.Vector3(this.width / 2.0, 0, this.length / 2.0)).subtract(new BABYLON.Vector3(0.5, 0, 0.5));
    }

    private wallThickness: number = 0.1;
    private cornerPieceWidth: number = 0.05;
    private sidePanelWidth: number = 0.100;
    private wareHouseRoofAngle:number = 20;
    private fieldshedRoofAngle:number = 20; //TODO every 0.1 reference should be replaced by this variable 
    private sidewaysShedRoofAngle:number = 11;

    private width: Width;
    private length: number;
    private height: number;
    private shedPosition: BABYLON.Vector3;
    private hasGutter: GutterOption = GutterOption.OneSide;
    private barnType: ShedType = ShedType.Warehouse;
    private smallWallHeight: number;
    private sizeChanged:boolean = true;

    private smallWallType: SmallWallType = SmallWallType.Concrete;
    private smallWallMaterial: SmallWallMaterial = SmallWallMaterial.SmoothConcrete;
    private wallType: WallType = WallType.LarssenSheetPiling;
    private roofType: RoofType = RoofType.LarssenSheetPiling;

    meshes:Array<BABYLON.AbstractMesh> = new Array<BABYLON.AbstractMesh>();
    shadowCasters:Array<BABYLON.AbstractMesh> = new Array<BABYLON.AbstractMesh>();
    grassMeshes:Array<BABYLON.AbstractMesh> = new Array<BABYLON.AbstractMesh>();

    public disabledGrassCounter:number = 0;

    facadeElementBuilder: FacadeElementBuilder;

    unfrozenMaterialNames:Array<string> = new Array<string>();

    private sceneManager: SceneManager;
    private engine: BABYLON.Engine;

    constructor(engine:BABYLON.Engine, shedPosition:BABYLON.Vector3, scene:BABYLON.Scene, sceneManager:SceneManager, highLightLayer:BABYLON.HighlightLayer) {
        this.shedPosition = shedPosition;
        this.facadeElementBuilder = new FacadeElementBuilder(this, highLightLayer); 
        
        let that = this;
        scene.onAfterCameraRenderObservable.add(function(){
            if(that.unfrozenMaterialNames.length !== 0) {
                that.unfrozenMaterialNames.forEach(value =>{
                    let material = scene.getMaterialByName(value);
                    material.freeze();
                });
                that.unfrozenMaterialNames = new Array<string>();
            }
        });
        this.engine = engine;
        this.sceneManager = sceneManager;
    }

    async BuildShedMesh(shedMeshSupplier: ShedMeshSupplier, shedMaterialSupplier: ShedMaterialSupplier, scene: BABYLON.Scene, shadowGenerator: BABYLON.ShadowGenerator) {
        this.BuildCornerPillars(shedMaterialSupplier, shedMeshSupplier, scene);
        this.BuildWallMeshes(shedMeshSupplier, scene);
        let roofTopLocation = this.BuildRoofMeshes(shedMaterialSupplier, shedMeshSupplier, scene);
        this.BuildFrontWallMeshes(shedMeshSupplier, scene, roofTopLocation);
        this.BuildSpantMeshes(shedMeshSupplier, scene);
        this.BuildSmallWallMeshes(shedMaterialSupplier, shedMeshSupplier, scene);
        this.BuildTilePlane(shedMaterialSupplier);
        if(this.sizeChanged) {
            this.sizeChanged = false;
            this.BuildGrass(shedMeshSupplier, scene);
        }
        if (this.hasGutter) {
            this.BuildGutterMeshes(shedMeshSupplier, scene);
        }

        this.FreezeMeshes();
        this.AddShadowCasters(shadowGenerator);
        
        shedMaterialSupplier.UpdateSmallWallMaterialUVs(this, this.smallWallType, this.SmallWallHeight / 2, this.SmallWallMaterial);
        
        //If this line is placed before adding shadow casters, suddenly after this first facadeelement is added the shadow dissapears
        //Because we don't really cast any shadows from facade elements I moved it here
        await this.facadeElementBuilder.BuildFacadeElement(shedMeshSupplier, shedMaterialSupplier, scene);
    }

    async UpdateShed(shedMeshSupplier: ShedMeshSupplier, shedMaterialSupplier: ShedMaterialSupplier, scene: BABYLON.Scene, shadowGenerator: BABYLON.ShadowGenerator) {
        this.CleanShedMesh();
        await this.BuildShedMesh(shedMeshSupplier, shedMaterialSupplier, scene, shadowGenerator);
    }

    private CleanShedMesh() {
        this.meshes.forEach(mesh => {
            mesh.dispose();
        });
        if(this.sizeChanged) {
            this.grassMeshes.forEach(mesh => {
                mesh.dispose();
            });
            this.grassMeshes = new Array<BABYLON.AbstractMesh>();
        }
        this.meshes = new Array<BABYLON.AbstractMesh>();
        this.shadowCasters = new Array<BABYLON.AbstractMesh>();
    }

    private BuildCornerPillars(shedMaterialSupplier:ShedMaterialSupplier, shedMeshSupplier:ShedMeshSupplier, scene :BABYLON.Scene) : void {
        var leftBottomPillarPosition = this.GetLeftBottomTilePosition().add(new BABYLON.Vector3(-0.5 + this.wallThickness/2, this.SmallWallHeight, -0.5 + this.wallThickness/2));
        var leftBackPillarPosition = this.GetLeftBottomTilePosition().add(new BABYLON.Vector3(-0.5 + this.wallThickness/2, this.SmallWallHeight, -0.5 + this.length - this.wallThickness/2));
        var rightBackPillarPosition = this.GetRightBackTilePosition().add(new BABYLON.Vector3(0.5 - this.wallThickness/2, this.SmallWallHeight, 0.5 - this.wallThickness/2));
        var rightFrontPillarPosition = this.GetRightBackTilePosition().add(new BABYLON.Vector3(0.5 - this.wallThickness/2, this.SmallWallHeight, 0.5 - this.length + this.wallThickness/2));

        var wallHeight = this.height - this.SmallWallHeight;
        this.BuildPillar(shedMaterialSupplier, shedMeshSupplier, scene, wallHeight, "LeftBottomPillar", leftBottomPillarPosition, BABYLON.Vector3.Backward());
        this.BuildPillar(shedMaterialSupplier, shedMeshSupplier, scene, wallHeight, "LeftBackPillar", leftBackPillarPosition, BABYLON.Vector3.Left());
        if(this.barnType === ShedType.SideWaysShed) {
            wallHeight += Math.tan(BABYLON.Tools.ToRadians(this.sidewaysShedRoofAngle)) * this.width;
        }
        if (this.barnType === ShedType.FieldShed) {
            wallHeight += this.width * 0.1;
        }
        this.BuildPillar(shedMaterialSupplier, shedMeshSupplier, scene, wallHeight, "RightBackPillar", rightBackPillarPosition, BABYLON.Vector3.Forward());
        this.BuildPillar(shedMaterialSupplier, shedMeshSupplier, scene, wallHeight, "RightBottomPillar", rightFrontPillarPosition,  BABYLON.Vector3.Right());
    }

    private BuildWallMeshes(shedMeshSupplier: ShedMeshSupplier, scene: BABYLON.Scene): void {
        var leftBottomCorner = this.GetLeftBottomTilePosition().add(new BABYLON.Vector3(0, this.SmallWallHeight, this.length / 2 - 0.5));
        var rightBackCorner = this.GetRightBackTilePosition().add(new BABYLON.Vector3(0, this.SmallWallHeight, -this.length / 2 + 0.5));
        var wallHeight = this.height;
        this.BuildSideWall(shedMeshSupplier, scene, wallHeight, leftBottomCorner, BABYLON.Vector3.Left());
        var rightWallHeight = wallHeight;
        if (this.barnType === ShedType.SideWaysShed) {
            rightWallHeight += Math.tan(BABYLON.Tools.ToRadians(this.sidewaysShedRoofAngle)) * this.width;
            this.BuildSideWall(shedMeshSupplier, scene, rightWallHeight, rightBackCorner, BABYLON.Vector3.Right());
        }
        else if (this.barnType === ShedType.Warehouse) {
            this.BuildSideWall(shedMeshSupplier, scene, wallHeight, rightBackCorner, BABYLON.Vector3.Right());
        }
    }

    private BuildSmallWallMeshes(shedMaterialSupplier:ShedMaterialSupplier, shedMeshSupplier:ShedMeshSupplier, scene :BABYLON.Scene) : void {
        var leftBottomCorner = this.GetLeftBottomTilePosition();
        var rightBackCorner = this.GetRightBackTilePosition();
        this.BuildSmallWall(shedMaterialSupplier, shedMeshSupplier, scene, this.width, this.SmallWallHeight, "SmallFrontWall", leftBottomCorner, BABYLON.Vector3.Right(), BABYLON.Vector3.Backward());
        this.BuildSmallWall(shedMaterialSupplier, shedMeshSupplier, scene, this.length, this.SmallWallHeight, "SmallLeftWall", leftBottomCorner, BABYLON.Vector3.Forward(), BABYLON.Vector3.Left());
        this.BuildSmallWall(shedMaterialSupplier, shedMeshSupplier, scene, this.width, this.SmallWallHeight, "SmallBackWall", rightBackCorner, BABYLON.Vector3.Left(), BABYLON.Vector3.Forward());
        if(this.barnType !== ShedType.FieldShed){
            this.BuildSmallWall(shedMaterialSupplier, shedMeshSupplier, scene, this.length, this.SmallWallHeight, "SmallRightWall", rightBackCorner, BABYLON.Vector3.Backward(), BABYLON.Vector3.Right());
        }
    }

    private BuildRoofMeshes(shedMaterialSupplier:ShedMaterialSupplier, shedMeshSupplier: ShedMeshSupplier, scene: BABYLON.Scene): BABYLON.Vector3 {
        var leftBottomStartPosition = this.GetLeftBottomTilePosition().add(new BABYLON.Vector3(0, this.height, 0)).add(new BABYLON.Vector3(-0.5, 0, 0));
        var rightBackStartPosition = this.GetRightBackTilePosition().add(new BABYLON.Vector3(0, this.height, 0)).add(new BABYLON.Vector3(0.5, 0, 0));

        var rotationDegrees = this.wareHouseRoofAngle;
        if(this.barnType === ShedType.SideWaysShed){
            rotationDegrees = this.sidewaysShedRoofAngle;
        }
        var singleRoofWidth = this.width / 2;
        if (this.barnType === ShedType.SideWaysShed) {
            singleRoofWidth *= 2;
        }
        var extraHeight = singleRoofWidth * Math.tan(BABYLON.Tools.ToRadians(rotationDegrees));
        if (this.barnType === ShedType.SideWaysShed) {
            extraHeight = Math.tan(BABYLON.Tools.ToRadians(rotationDegrees)) * this.width;
        }


        var leftRoofDirection = BABYLON.Vector3.Right();
        var rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(0, 0, BABYLON.Tools.ToRadians(rotationDegrees));
        leftRoofDirection = leftRoofDirection.rotateByQuaternionToRef(rotationQuaternion, leftRoofDirection);
        
        let initialLength = Math.sqrt(singleRoofWidth * singleRoofWidth + extraHeight * extraHeight);
        let tempIntersectionVec3 = leftBottomStartPosition.add(leftRoofDirection.multiplyByFloats(initialLength,initialLength,initialLength));
        let intersection = new BABYLON.Vector2(tempIntersectionVec3.x, tempIntersectionVec3.y);

        let roofTop = new BABYLON.Vector3(intersection.x, intersection.y, leftBottomStartPosition.z);

        if (this.barnType !== ShedType.SideWaysShed) {
            let smallExtraHeight = singleRoofWidth * 2 * 0.1;
            if (this.barnType === ShedType.FieldShed) {
                rightBackStartPosition = rightBackStartPosition.add(new BABYLON.Vector3(0, smallExtraHeight, 0));
            }

            let rightRoofDirection = BABYLON.Vector3.Left();
            let rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(0, 0, BABYLON.Tools.ToRadians(-rotationDegrees));
            rightRoofDirection = rightRoofDirection.rotateByQuaternionToRef(rotationQuaternion, rightRoofDirection);
            
            intersection = UtilityFunctions.GetPointOfIntersection(
                new BABYLON.Vector2(leftBottomStartPosition.x, leftBottomStartPosition.y), 
                new BABYLON.Vector2(rightBackStartPosition.x, rightBackStartPosition.y), 
                new BABYLON.Vector2(leftRoofDirection.x, leftRoofDirection.y), 
                new BABYLON.Vector2(rightRoofDirection.x, rightRoofDirection.y));

            let rightRoofLength = intersection.subtract(new BABYLON.Vector2(rightBackStartPosition.x, rightBackStartPosition.y)).length();

            let rightRoofOffset = rightRoofDirection.multiply(new BABYLON.Vector3(rightRoofLength / 2, rightRoofLength / 2, rightRoofLength / 2));
            let rightRoofSpawnPosition = rightBackStartPosition.add(rightRoofOffset);

            this.BuildRoof(shedMaterialSupplier, shedMeshSupplier, scene, this.length, rightRoofLength, "RightRoof", rightRoofSpawnPosition, BABYLON.Vector3.Backward(), rightRoofOffset.normalize());

            let roofTopMesh = (shedMeshSupplier.GetRoofTop(scene) as BABYLON.Mesh);
            roofTopMesh.material = shedMaterialSupplier.GetRoofMaterial();
            
            roofTop = new BABYLON.Vector3(intersection.x, intersection.y, leftBottomStartPosition.z);

            //i=1 and i < this.length-1 so we can skip the first and final roofpiece
            for(var i = 1; i < this.length-1; i++){
                let roofTopMeshCopy = roofTopMesh.createInstance("roofTop");
                roofTopMeshCopy.position = roofTop.add(BABYLON.Vector3.Forward().multiplyByFloats(i,i,i)).add(BABYLON.Vector3.Up().multiplyByFloats(0.105,0.105,0.105));
                this.meshes.push(roofTopMeshCopy);
            }

            let firstTopMesh = roofTopMesh.createInstance("roofTop");
            let roofScale = 1.04;
            firstTopMesh.position = roofTop.add(BABYLON.Vector3.Up().multiplyByFloats(0.105,0.105,0.105));
            firstTopMesh.scaling = new BABYLON.Vector3(1, 1, roofScale);
            this.meshes.push(firstTopMesh);

            let lastTopMesh = roofTopMesh.createInstance("roofTop");
            lastTopMesh.position = roofTop.add(BABYLON.Vector3.Forward().multiplyByFloats(this.length-1,this.length-1,this.length-1)).add(BABYLON.Vector3.Up().multiplyByFloats(0.105,0.105,0.105));
            lastTopMesh.scaling = new BABYLON.Vector3(1, 1, roofScale);
            this.meshes.push(lastTopMesh);

            //Build logo
            let newHuismanLogoPanel = (shedMeshSupplier.GetLogoPanel(scene) as BABYLON.Mesh).createInstance("LogoPanel");
            let newHuismanLogo = (shedMeshSupplier.GetLogo(scene) as BABYLON.Mesh).createInstance("LogoPanel");

            let logoHeightOffset = 0.125;

            let frontRoofTop = new BABYLON.Vector3(roofTop.x, roofTop.y, roofTop.z);
            frontRoofTop.z = this.shedPosition.z + this.length/2;
            frontRoofTop.y += logoHeightOffset;

            let logoOffset = 0.025;

            let forwardDistanceVector = BABYLON.Vector3.Forward().multiplyByFloats(logoOffset,logoOffset,logoOffset);
            newHuismanLogo.setDirection(BABYLON.Vector3.Backward());
            newHuismanLogo.position = frontRoofTop.add(forwardDistanceVector.add(new BABYLON.Vector3(0,0,0.02)));
            newHuismanLogoPanel.setDirection(BABYLON.Vector3.Forward());
            newHuismanLogoPanel.position = frontRoofTop.add(forwardDistanceVector);

            this.meshes.push(newHuismanLogo);
            this.meshes.push(newHuismanLogoPanel);

            let newHuismanLogoPanel2 = (shedMeshSupplier.GetLogoPanel(scene) as BABYLON.Mesh).createInstance("LogoPanel");
            let newHuismanLogo2 = (shedMeshSupplier.GetLogo(scene) as BABYLON.Mesh).createInstance("LogoPanel");

            let backRoofTop = new BABYLON.Vector3(roofTop.x, roofTop.y, roofTop.z);
            backRoofTop.z = this.shedPosition.z - this.length/2;
            backRoofTop.y += logoHeightOffset;

            let backwardDistanceVector = BABYLON.Vector3.Backward().multiplyByFloats(logoOffset,logoOffset,logoOffset);
            newHuismanLogo2.setDirection(BABYLON.Vector3.Forward());
            newHuismanLogo2.position = backRoofTop.add(backwardDistanceVector.add(new BABYLON.Vector3(0,0,-0.02)));
            newHuismanLogoPanel2.setDirection(BABYLON.Vector3.Backward());
            newHuismanLogoPanel2.position = backRoofTop.add(backwardDistanceVector);

            this.meshes.push(newHuismanLogo2);
            this.meshes.push(newHuismanLogoPanel2);
        }

        let leftRoofLength = intersection.subtract(new BABYLON.Vector2(leftBottomStartPosition.x, leftBottomStartPosition.y)).length();
        let leftRoofOffset = leftRoofDirection.multiply(new BABYLON.Vector3(leftRoofLength / 2, leftRoofLength / 2, leftRoofLength / 2));
        let leftRoofSpawnPosition = leftBottomStartPosition.add(leftRoofOffset);

        this.BuildRoof(shedMaterialSupplier, shedMeshSupplier, scene, this.length, leftRoofLength, "LeftRoof", leftRoofSpawnPosition, BABYLON.Vector3.Forward(), leftRoofOffset.normalize());

        return roofTop;
    }

    private BuildFrontWallMeshes(shedMeshSupplier: ShedMeshSupplier, scene: BABYLON.Scene, roofTopLocation: BABYLON.Vector3): void {
        let leftBottomCorner = this.GetLeftBottomTilePosition();
        let rightBackCorner = this.GetRightBackTilePosition();
        let sliceTriangleA: BABYLON.Vector3[] = [];
        let sliceTriangleB: BABYLON.Vector3[] = [];
        roofTopLocation.z = 0;
        roofTopLocation.y -= this.SmallWallHeight;

        switch (this.barnType) {
            case ShedType.Warehouse:
                var height = this.width / 2 * Math.tan(BABYLON.Tools.ToRadians(this.wareHouseRoofAngle));
                sliceTriangleA = [
                    new BABYLON.Vector3(0, height + this.height - this.SmallWallHeight, 0),
                    new BABYLON.Vector3(this.width / -2, this.height - this.SmallWallHeight, 0),
                    new BABYLON.Vector3(this.width / -2, 0, 0),
                    new BABYLON.Vector3(this.width / 2, 0, 0),
                    new BABYLON.Vector3(this.width / 2, this.height - this.SmallWallHeight, 0),
                ];
                sliceTriangleB = sliceTriangleA;
                break;
            case ShedType.FieldShed:
                var height = this.width / 2 * Math.tan(BABYLON.Tools.ToRadians(this.fieldshedRoofAngle));
                var smallExtraHeight = this.width * 0.1;
                sliceTriangleA = [
                    new BABYLON.Vector3(-roofTopLocation.x, roofTopLocation.y, roofTopLocation.z),
                    new BABYLON.Vector3(this.width / -2, smallExtraHeight + this.height - this.SmallWallHeight, 0),
                    new BABYLON.Vector3(this.width / -2, 0, 0),
                    new BABYLON.Vector3(this.width / 2, 0, 0),
                    new BABYLON.Vector3(this.width / 2, this.height - this.SmallWallHeight, 0),
                ];
                sliceTriangleB = [
                    roofTopLocation,
                    new BABYLON.Vector3(this.width / -2, this.height - this.SmallWallHeight, 0),
                    new BABYLON.Vector3(this.width / -2, 0, 0),
                    new BABYLON.Vector3(this.width / 2, 0, 0),
                    new BABYLON.Vector3(this.width / 2, smallExtraHeight + this.height - this.SmallWallHeight, 0),
                ];
                break;
            case ShedType.SideWaysShed:
                var height = Math.tan(BABYLON.Tools.ToRadians(this.sidewaysShedRoofAngle)) * this.width;
                var smallExtraHeight = Math.tan(BABYLON.Tools.ToRadians(this.sidewaysShedRoofAngle)) * this.width;
                sliceTriangleA = [
                    new BABYLON.Vector3(this.width / -2, height + this.height - this.SmallWallHeight, 0),
                    new BABYLON.Vector3(this.width / -2, 0, 0),
                    new BABYLON.Vector3(this.width / 2, 0, 0),
                    new BABYLON.Vector3(this.width / 2, this.height - this.SmallWallHeight, 0),
                ];
                sliceTriangleB = [
                    new BABYLON.Vector3(this.width / -2, this.height - this.SmallWallHeight, 0),
                    new BABYLON.Vector3(this.width / -2, 0, 0),
                    new BABYLON.Vector3(this.width / 2, 0, 0),
                    new BABYLON.Vector3(this.width / 2, smallExtraHeight + this.height - this.SmallWallHeight, 0),
                ];
                break;
        }
        this.BuildWall(shedMeshSupplier, scene, sliceTriangleA, leftBottomCorner.add(new BABYLON.Vector3(this.width / 2.0 - 0.5, this.SmallWallHeight, 0)), BABYLON.Vector3.Backward());
        this.BuildWall(shedMeshSupplier, scene, sliceTriangleB, rightBackCorner.add(new BABYLON.Vector3(-this.width / 2.0 + 0.5, this.SmallWallHeight, 0)), BABYLON.Vector3.Forward());
    }

    private BuildGutterMeshes(shedMeshSupplier: ShedMeshSupplier, scene: BABYLON.Scene): void {
        var leftBottomCorner = this.GetLeftBottomTilePosition();
        var rightBackCorner = this.GetRightBackTilePosition();
        if(this.hasGutter !== GutterOption.None) {
            if(this.barnType === ShedType.FieldShed) {
                let smallExtraHeight = this.barnType === ShedType.FieldShed ? this.width * 0.1 : 0;
                this.BuildGutter(shedMeshSupplier, scene, this.length, this.height + smallExtraHeight, "RightGutter", rightBackCorner, BABYLON.Vector3.Backward(), BABYLON.Vector3.Right());
                if (this.hasGutter !== GutterOption.OneSide) {
                    this.BuildGutter(shedMeshSupplier, scene, this.length, this.height, "LeftGutter", leftBottomCorner, BABYLON.Vector3.Forward(), BABYLON.Vector3.Left());
                }
            }
            else{
                this.BuildGutter(shedMeshSupplier, scene, this.length, this.height, "LeftGutter", leftBottomCorner, BABYLON.Vector3.Forward(), BABYLON.Vector3.Left());
                if (this.barnType !== ShedType.SideWaysShed && this.hasGutter !== GutterOption.OneSide) {
                    this.BuildGutter(shedMeshSupplier, scene, this.length, this.height, "RightGutter", rightBackCorner, BABYLON.Vector3.Backward(), BABYLON.Vector3.Right());
                }
            }
        }
    }

    private BuildSpantMeshes(shedMeshSupplier: ShedMeshSupplier, scene: BABYLON.Scene) {
        if (this.barnType === ShedType.FieldShed) {
            var spantMesh = (shedMeshSupplier.GetSpantMesh(scene) as BABYLON.Mesh);
            var spantBottomMesh = (shedMeshSupplier.GetSpantBottomMesh(scene) as BABYLON.Mesh);
            let halfPillarWidth = spantMesh.getBoundingInfo().boundingBox.extendSize.x/2;
            var leftBottomCorner = this.GetRightBackTilePosition();
            var extraHeight = this.width * 0.1;
            for (var i = 5; i < this.length; i += 5) {
                var newSpantMesh = spantMesh.createInstance("Spant");
                let spantPosition = leftBottomCorner.add(BABYLON.Vector3.Backward().multiplyByFloats(i, i, i)).add(new BABYLON.Vector3(0.5 - halfPillarWidth, 0, 0.5));
                newSpantMesh.position = spantPosition;
                newSpantMesh.scaling = new BABYLON.Vector3(1, this.height + extraHeight, 1);
                this.meshes.push(newSpantMesh);

                var newSpantBottomMesh = spantBottomMesh.createInstance("SpantBottom");
                newSpantBottomMesh.position = spantPosition;
                this.meshes.push(newSpantBottomMesh);
            }
        }
    }

    private BuildPillar(
        shedMaterialSupplier:ShedMaterialSupplier,
        shedMeshSupplier:ShedMeshSupplier, 
        scene :BABYLON.Scene, 
        height:number,
        pillarName:string, 
        position:BABYLON.Vector3,
        lookDirection:BABYLON.Vector3) : void
    {
        var pillarMesh = (shedMeshSupplier.GetPillarMesh(scene) as BABYLON.Mesh);
        pillarMesh.material = shedMaterialSupplier.GetWallWithoutBumpMaterial(this.wallType);
        var newPillarMesh = pillarMesh.createInstance(pillarName);
        newPillarMesh.position = position;
        newPillarMesh.scaling = new BABYLON.Vector3(1, 1, 1).multiply(new BABYLON.Vector3(1, height, 1));
        newPillarMesh.setDirection(lookDirection);
        this.meshes.push(newPillarMesh);
    }

    private BuildSideWall(
        shedMeshSupplier: ShedMeshSupplier,
        scene: BABYLON.Scene,
        wallHeight: number,
        startPosition: BABYLON.Vector3,
        lookDirection: BABYLON.Vector3): void {
        let sliceTriangleA: BABYLON.Vector3[] = [];
        sliceTriangleA = [
            new BABYLON.Vector3(this.length / -2, wallHeight - this.SmallWallHeight, 0),
            new BABYLON.Vector3(this.length / -2, 0, 0),
            new BABYLON.Vector3(this.length / 2, 0, 0),
            new BABYLON.Vector3(this.length / 2, wallHeight - this.SmallWallHeight, 0),
        ];
        this.BuildWall(shedMeshSupplier, scene, sliceTriangleA, startPosition, lookDirection);
    }
    
    private BuildSmallWall (
        shedMaterialSupplier:ShedMaterialSupplier, 
        shedMeshSupplier:ShedMeshSupplier,
        scene :BABYLON.Scene, 
        length:number,
        height:number,
        wallName:string, 
        startPosition:BABYLON.Vector3, 
        direction:BABYLON.Vector3, 
        lookDirection:BABYLON.Vector3) : void 
    {
        if(this.smallWallType !== SmallWallType.None) {
            var smallWallMesh = (shedMeshSupplier.GetSmallWallMesh(scene, this.smallWallType) as BABYLON.Mesh);
            smallWallMesh.material = shedMaterialSupplier.GetSmallWallMaterial(this.smallWallType, this.smallWallMaterial);
            for(var i = 0; i < length; i++){
                var newSmallWallMesh = smallWallMesh.createInstance(wallName);
                var position = startPosition.add(direction.multiplyByFloats(i,i,i)).add(lookDirection.multiply(new BABYLON.Vector3(0.5,0.5,0.5)));
                newSmallWallMesh.position = position;
                newSmallWallMesh.scaling = new BABYLON.Vector3(1, 1, 1).multiply(new BABYLON.Vector3(1, height, 1));
                newSmallWallMesh.setDirection(lookDirection);
                
                this.meshes.push(newSmallWallMesh);
                this.shadowCasters.push(newSmallWallMesh);
            }
        }
    }

    private BuildRoof(shedMaterialSupplier:ShedMaterialSupplier,
        shedMeshSupplier: ShedMeshSupplier,
        scene: BABYLON.Scene,
        length: number,
        width: Width,
        wallName: string,
        centerPosition: BABYLON.Vector3,
        direction: BABYLON.Vector3,
        lookDirection: BABYLON.Vector3): void {
        //Build the roof
        let roofMesh = (shedMeshSupplier.GetRoofMesh(scene, this.roofType) as BABYLON.Mesh);
        
        for(var i = 0; i < length; i++){
            let roofMeshInstance = roofMesh.createInstance("roof");
            roofMeshInstance.position = centerPosition.add(direction.multiplyByFloats(i,i,i));
            roofMeshInstance.scaling = new BABYLON.Vector3(1,1,width);
            roofMeshInstance.setDirection(lookDirection);
            this.meshes.push(roofMeshInstance);
            this.shadowCasters.push(roofMeshInstance);
        }

        //Build panels on the side of the roof
        let roofFrontPanel = shedMeshSupplier.GetRoofFrontPanel(scene);
        roofFrontPanel.material = shedMaterialSupplier.GetRoofMaterial();
        let newFrontPanel = (roofFrontPanel as BABYLON.Mesh).createInstance(wallName + "_SidePanel");
        let tinyDetailPanelOffset = 0.1;
        
        let position1 = centerPosition.add(direction.multiply(new BABYLON.Vector3(length - 0.5 - this.sidePanelWidth, length - 0.5 - this.sidePanelWidth, length - 0.5 - this.sidePanelWidth)));
        position1 = position1.add(lookDirection.multiplyByFloats(tinyDetailPanelOffset,tinyDetailPanelOffset,tinyDetailPanelOffset).multiplyByFloats(-1,-1,-1));
        newFrontPanel.position = position1;
        newFrontPanel.scaling = new BABYLON.Vector3(1, 1, this.ShedType === ShedType.SideWaysShed ? width + tinyDetailPanelOffset * 2 : width);
        newFrontPanel.setDirection(lookDirection);
        this.meshes.push(newFrontPanel);

        let newFrontPanel2 = (roofFrontPanel as BABYLON.Mesh).createInstance(wallName + "_SidePanel");
        let position2 = centerPosition.add(direction.multiply(new BABYLON.Vector3(-0.5 + this.sidePanelWidth, -0.5 + this.sidePanelWidth, -0.5 + this.sidePanelWidth)));
        position2 = position2.add(lookDirection.multiplyByFloats(tinyDetailPanelOffset, tinyDetailPanelOffset, tinyDetailPanelOffset).multiplyByFloats(-1,-1,-1));
        newFrontPanel2.position = position2;
        newFrontPanel2.scaling = new BABYLON.Vector3(1, 1,  this.ShedType === ShedType.SideWaysShed ? width + tinyDetailPanelOffset * 2 : width);
        newFrontPanel2.setDirection(lookDirection.multiply(new BABYLON.Vector3(-1, -1, -1)));
        this.meshes.push(newFrontPanel2);

        if(this.ShedType === ShedType.SideWaysShed){
            let newFrontPanel3 = (roofFrontPanel as BABYLON.Mesh).createInstance(wallName + "_SidePanel");
            let position3 = this.ShedPosition.add(new BABYLON.Vector3(this.width/2, 0.015 + this.Height + Math.tan(BABYLON.Tools.ToRadians(this.sidewaysShedRoofAngle)) * this.width, 0));
            position3 = position3.add(lookDirection.multiplyByFloats(tinyDetailPanelOffset, tinyDetailPanelOffset, tinyDetailPanelOffset).multiplyByFloats(-1,-1,-1));
            newFrontPanel3.position = position3;
            newFrontPanel3.scaling = new BABYLON.Vector3(1, 1, length + 0.1);
            var matrix = BABYLON.Matrix.RotationAxis(BABYLON.Axis.Z, Math.PI / 2);
            var v2 = BABYLON.Vector3.TransformCoordinates(lookDirection, matrix);

            var crossDirection = BABYLON.Vector3.Cross(v2, lookDirection);

            newFrontPanel3.setDirection(crossDirection);

            this.meshes.push(newFrontPanel3);
        }
    }

    private BuildWall(
        shedMeshSupplier: ShedMeshSupplier,
        scene: BABYLON.Scene,
        sliceTriangleVerticePositions: BABYLON.Vector3[],
        startPosition: BABYLON.Vector3,
        lookDirection: BABYLON.Vector3,
        additionalRotation?: BABYLON.Vector3): void {

        var wallMesh = shedMeshSupplier.GetWallMesh(scene, sliceTriangleVerticePositions, this.wallType);
        
        wallMesh.position = startPosition.add(lookDirection.multiply(new BABYLON.Vector3(0.5, 0.5, 0.5)));
        wallMesh.setDirection(lookDirection);
        if (additionalRotation !== undefined) {
            wallMesh.setDirection(additionalRotation);
        }
        this.meshes.push(wallMesh);
        this.shadowCasters.push(wallMesh);
    }

    private BuildGutter(shedMeshSupplier: ShedMeshSupplier,
        scene: BABYLON.Scene,
        length: number,
        height: number,
        gutterName: string,
        startPosition: BABYLON.Vector3,
        direction: BABYLON.Vector3,
        lookDirection: BABYLON.Vector3): void {
        var horizontalGutter = (shedMeshSupplier.GetGutterHorizontalMesh(scene) as BABYLON.Mesh);
        var roofHeight = height;
        for (var i = 0; i < length; i++) {
            var spawnPosition = startPosition.add(direction.multiplyByFloats(i, i, i)).add(lookDirection.multiply(new BABYLON.Vector3(0.6, 0.6, 0.6)));
            if (i == 0) {
                var newVerticalGutter = (shedMeshSupplier.GetGutterVerticalMiddleMesh(scene) as BABYLON.Mesh).createInstance("VerticalGutter");
                newVerticalGutter.position = spawnPosition;
                newVerticalGutter.scaling = new BABYLON.Vector3(1, roofHeight, 1);
                this.meshes.push(newVerticalGutter);
            }
            var newHorizontalGutter = horizontalGutter.createInstance(gutterName);
            newHorizontalGutter.position = spawnPosition.add(new BABYLON.Vector3(0, roofHeight, 0));
            newHorizontalGutter.setDirection(lookDirection);
            this.meshes.push(newHorizontalGutter);
        }
    }

    private AddShadowCasters(shadowGenerator: BABYLON.ShadowGenerator){
        if(shadowGenerator !== null && shadowGenerator !== undefined){
            this.shadowCasters.forEach(element => {
                shadowGenerator.addShadowCaster(element);
            });
        }
    }

    private BuildTilePlane(shedMaterialSupplier: ShedMaterialSupplier) {
        let tileGround = BABYLON.MeshBuilder.CreatePlane("tileGround", { width: this.length + 8, height: this.width + 8 });
        tileGround.setDirection(BABYLON.Vector3.Down());
        tileGround.position = tileGround.position.add(new BABYLON.Vector3(0,0.01,0));
        tileGround.material = shedMaterialSupplier.GetGroundTileMaterial();
        if(!UtilityFunctions.detectAndroid()){
            tileGround.receiveShadows = true;
        }
        this.sceneManager.upperHemisphere.excludedMeshes = [tileGround];
        this.meshes.push(tileGround);
    }

    private BuildGrass(shedMeshSupplier: ShedMeshSupplier, scene: BABYLON.Scene) {
        let grassMesh = (shedMeshSupplier.GetGrassModel(scene) as BABYLON.Mesh);
        let radius = 150;
        for(let i = 0; i < 10000 - this.disabledGrassCounter; i++){
            let t = 2 * Math.PI * Math.random();
            let u = Math.random() + Math.random();
            let r = u > 1 ? 2 - u : u;
            let coordinate = new BABYLON.Vector2(r * Math.cos(t) * radius, r * Math.sin(t) * radius);

            if ((coordinate.x < -this.width / 2 - 4 || coordinate.x > this.width / 2 + 4) ||
                (coordinate.y < -this.length / 2 - 4 || coordinate.y > this.length / 2 + 4)) {
                let grass = grassMesh.createInstance("Grass");
                grass.position = new BABYLON.Vector3(coordinate.x,0,coordinate.y);
                grass.setDirection(new BABYLON.Vector3(Math.random(), 0, Math.random()));
                grass.scaling = new BABYLON.Vector3(7 + Math.random() * 3,2 + Math.random() * 2,7 + Math.random() * 3);
                this.grassMeshes.push(grass);
            }
        }
    }

    private FreezeMeshes() {
        this.meshes.forEach((value) => {
            value.freezeWorldMatrix();
        });
    }

    public SetWallColor(color:BABYLON.Color3, shedMaterialSupplier:ShedMaterialSupplier){
        var wallMaterial: BABYLON.PBRMaterial = (shedMaterialSupplier.GetWallMaterial(this.wallType) as BABYLON.PBRMaterial);
        if(wallMaterial !== null) {
            wallMaterial.unfreeze();
            wallMaterial.albedoColor = color;
            this.unfrozenMaterialNames.push(wallMaterial.name);
        }
        
        var bumplessMaterial = (shedMaterialSupplier.GetWallWithoutBumpMaterial(this.wallType) as BABYLON.PBRMaterial);
        if(bumplessMaterial !== null) {
            bumplessMaterial.unfreeze();
            bumplessMaterial.albedoColor = color;
            this.unfrozenMaterialNames.push(bumplessMaterial.name);
        }
    }

    public SetRoofColor(color:BABYLON.Color3, shedMaterialSupplier:ShedMaterialSupplier){
        var roofMaterial: BABYLON.PBRMaterial = (shedMaterialSupplier.GetRoofMaterial() as BABYLON.PBRMaterial);
        if(roofMaterial !== null){
            roofMaterial.unfreeze();
            roofMaterial.albedoColor = color;
            this.unfrozenMaterialNames.push(roofMaterial.name); 
        }
    }

    public SetSmallWallColor(color:BABYLON.Color3, shedMaterialSupplier:ShedMaterialSupplier){
        var smallWallMaterial: BABYLON.PBRMaterial = (shedMaterialSupplier.GetSmallWallMaterial(this.smallWallType, this.SmallWallMaterial) as BABYLON.PBRMaterial);
        if(smallWallMaterial !== null && this.smallWallType == SmallWallType.DampProof) {
            smallWallMaterial.unfreeze();
            smallWallMaterial.albedoColor = color;
            this.unfrozenMaterialNames.push(smallWallMaterial.name);
        }
    }
}